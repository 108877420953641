import { BASE_PATH, API_VERSION } from './config';
import { getAccessTokenApi, getAdminAccessTokenApi } from './auth';
import { fromImage } from 'imtool';

export async function getProductImageApi(image) {
  const url = `${BASE_PATH}/${API_VERSION}/get-product-image/${image}`;

  return fetch(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
    .then((response) => {
      return response.url;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function addProductApi(product, categoryId) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/${categoryId}/add-product`;
  const params = {
    method: 'POST',
    body: JSON.stringify(product),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return await fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function uploadProductImage(categoryId, productId, image) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/upload-product-image/${categoryId}/${productId}`;
  fromImage(image.file)
    .then((tool) => tool.thumbnail(400, false).toBlob())
    .then((blob) => {
      const formData = new FormData();
      formData.append('productImage', blob, `${image.name}.webp`);
      console.log('blob');
      console.log(blob);
      const params = {
        method: 'PUT',
        body: formData,
        headers: {
          Authorization: token,
        },
      };
      return fetch(url, params)
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          return result;
        })
        .catch((err) => {
          return err.message;
        });
    })
    .catch((e) => {
      console.log(e);
    });
}

export async function deleteProductApi(productId, categoryId) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/${categoryId}/drop-product/${productId}`;
  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  console.log(params);

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function updateProductApi(product, categoryId, productId) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/${categoryId}/update-product/${productId}`;

  const params = {
    method: 'PUT',
    body: JSON.stringify(product),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };
  console.log(params.body);
  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
