import React from 'react';
import { Table, Button, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import './ListUsers.scss';
import { Link } from 'react-router-dom';

export default function ListUsers(props) {
  const { usersActive } = props;
  return (
    <Table striped bordered hover size="md" responsive>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Apellidos</th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>Cód. Postal</th>
          <th>Fecha de alta</th>
          {/* <th>Último acceso</th> */}
          <th>Acciones</th>
        </tr>
      </thead>
      <tbody>
        {usersActive ? (
          usersActive.map((e) => {
            return (
              <tr>
                <td>{e.name ? e.name : 'Sin nombre'}</td>
                <td>{e.lastName ? e.lastName : 'Sin apellido'}</td>
                <td>{e.email ? e.email : 'Sin email'}</td>
                <td>{e.phoneNumber ? e.phoneNumber : 'Sin teléfono'}</td>
                <td>{e.postalCode ? e.postalCode : ''}</td>
                <td>{new Date(e.createdAt).toLocaleDateString() || ''}</td>
                {/* <td>{e.lastLogin ? e.lastLogin : ''}</td> */}
                <td>
                  <ButtonGroup>
                    <Link to={`/elpauporcjaris/pedidos/${e._id}`}>
                      <Button variant="outline-primary">Ver pedidos</Button>
                    </Link>

                    <Button variant="outline-danger">
                      <FontAwesomeIcon icon={faBan} />
                    </Button>
                  </ButtonGroup>
                </td>
              </tr>
            );
          })
        ) : (
          <div>No hay Usuarios todavía</div>
        )}
      </tbody>
    </Table>
  );
}
