import React, { useEffect, useState } from 'react';
import ProductsTable from './ProductsTable';
import CheckOutForm from './CheckOutForm';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import useAuth from '../../../../hooks/useAuth';
import useConfig from '../../../../hooks/useConfig';
import { useNavigate } from 'react-router-dom';
import { getCartApi } from '../../../../api/cart';
import { getCategoriesApi } from '../../../../api/category';
import Button from 'react-bootstrap/esm/Button';
import { getTimesApi } from '../../../../api/settings';

import './Checkout.scss';

const emptyCart = require('../../../../assets/img/png/emptycart.png');

export default function Checkout(props) {
  const [cart, setCart] = useState(null);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState(null);
  const [refreshCartItems, setRefreshCartItems] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const { user, isLoading } = useAuth();
  const { acceptingOrders } = useConfig();
  const [sendingOrder, setSendingOrder] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if ((!user && !isLoading) || !acceptingOrders) {
      navigate('/carta');
    }
  }, [isLoading, acceptingOrders, user, navigate]);

  useEffect(() => {
    if (cart === null || refreshCartItems)
      getCartApi()
        .then((response) => {
          setCart(response);
        })
        .catch((e) => {
          setCart(null);
        });
    setRefreshCartItems(false);
  }, [user, refreshCartItems, cart]);

  useEffect(() => {
    if (schedule === null && user) {
      getTimesApi()
        .then((response) => {
          setSchedule(response);
        })
        .catch((e) => {
          setSchedule(null);
        });
    }
  }, [schedule, user]);

  useEffect(() => {
    if (categories === null)
      getCategoriesApi()
        .then((response) => {
          setCategories(response.categories);
        })
        .catch((e) => {
          //console.log(e);
        });
  }, [cart, user, categories]);

  useEffect(() => {
    let arr = [];
    if (categories !== null && cart !== null)
      if (cart.products) {
        cart.products.forEach((element) => {
          categories.forEach((item) => {
            if (element.categoryId === item._id) {
              item.products.forEach((product) => {
                if (element._id === product._id) {
                  product.categoryId = item._id;
                  product.categoryName = item.name;
                  element.variants.forEach((variant) => {
                    product.variant[variant.index] = {
                      ...product.variant[variant.index],
                      quantity: variant.quantity,
                    };
                  });
                  arr.push(product);
                }
              });
            }
          });
        });
      }
    setProducts(arr);
  }, [cart, categories, user]);

  const openMorningTime = 10;
  const closeMorningTime = 16;
  const openEveningTime = 20;
  const closeEveningTime = 23;

  let timesArray = [];

  for (let i = openMorningTime; i < closeMorningTime; i++) {
    for (let j = 0; j < 60; j += 15) {
      if (j === 0) timesArray.push(`${i}:${j}0`);
      else timesArray.push(`${i}:${j}`);
    }
  }

  for (let i = openEveningTime; i < closeEveningTime; i++) {
    for (let j = 0; j < 60; j += 15) {
      if (j === 0) timesArray.push(`${i}:${j}0`);
      else timesArray.push(`${i}:${j}`);
    }
  }

  return (
    <Container>
      {products.length > 0 && (
        <>
          <h1>Resumen del pedido</h1>
          <ProductsTable
            sendingOrder={sendingOrder}
            setProducts={setProducts}
            products={products ? products : []}
            setRefreshCartItems={setRefreshCartItems}
          />
          <CheckOutForm
            acceptingOrders={acceptingOrders}
            times={timesArray}
            schedule={schedule}
            sendingOrder={sendingOrder}
            setSendingOrder={setSendingOrder}
            products={products ? products : []}
          />
        </>
      )}
      {products.length === 0 && (
        <>
          <div className="empty-cart">
            <h3>Agrega productos al carrito y aparecerán aquí</h3>
            <img
              className="cart-image"
              src={emptyCart}
              alt="el pollo paulino carrito vacio"
            />
            <div className="show-menu-button">
              <Link to="/carta">
                <Button variant="warning" size="md">
                  Ver la carta
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}
