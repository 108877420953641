import React from 'react';

import './Footer.scss';

import logoPaulino from '../../../../assets/img/logo/logo-rotulo.png';

import logoInsta from '../../../../assets/img/svg/logo-instagram.svg';
import logoFacebook from '../../../../assets/img/svg/logo-facebook.svg';
import logoMail from '../../../../assets/img/svg/mail-icon.svg';

export default function Footer(props) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer__logo">
        <img src={logoPaulino} alt="logo el pollo paulino" />
      </div>
      <div className="footer__social">
        <div className="footer__social__logos">
          <a href="https://www.instagram.com/elpollopaulino">
            <img src={logoInsta} alt="logo instagram" />
          </a>
          <a href="https://www.facebook.com/elpollopaulino/">
            <img src={logoFacebook} alt="logo facebook" />
          </a>
          <a href="mailto:info@elpollopaulino.com">
            <img src={logoMail} alt="logo email" />
          </a>
        </div>

        <p>
          <a href="/privacidad-y-condiciones-de-uso">
            Política de privacidad y condiciones de uso
          </a>
        </p>
        <p>
          © {currentYear} El Pollo Paulino
          <br />
          <a href="https://goo.gl/maps/m1aUwBuo6Luodn3G9">
            C. Paulino Caballero, 18, 31003 Pamplona, Navarra
          </a>
        </p>
        <p>
          Sitio web desarrolado por{' '}
          <a href="https://www.pablooses.com" target="_blank" rel="noreferrer">
            Pablo Osés
          </a>
        </p>
      </div>
    </footer>
  );
}
