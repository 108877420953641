import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import './Activation.scss';
import { activateUserWithTokenApi } from '../../../../api/user';

const accountActivatedPicture = require('../../../../assets/img/png/account-activated.png');

export default function Activation() {
  let { userId, token, activationId } = useParams();
  const [accountActivated, setAccountActivated] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    activateUserWithTokenApi(activationId, userId, token)
      .then((response) => {
        setAccountActivated(true);
        setTimeout(() => {
          navigate('/carta', { replace: true });
        }, 2000);
      })
      .catch((e) => {
        console.log(e);
        setAccountActivated(false);
      });
  }, []);

  return (
    <>
      <div className="activation-container">
        {accountActivated && <h4>Cuenta activada con éxito</h4>}
        {!accountActivated && <h4>Cuenta no activada 😥</h4>}
        <br />
        <img
          className="activation-image"
          src={accountActivatedPicture}
          alt="el pollo paulino cuenta activada"
        />
        <br />
        <div className="show-menu-button">
          <Link to="/carta">
            <Button variant="warning" size="md">
              Ver la carta
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
