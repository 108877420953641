import React from 'react';

import './Header.scss';

export default function Header(props) {
  return (
    <header className="landing-container__items__header">
      <Title text="Paulino" slogan="¡Que me aproveche!" />
    </header>
  );
}

function Title(props) {
  const { text, slogan } = props;

  return (
    <div className="landing-container__items__header__title">
      <h1>{text}</h1>
      <h2>{slogan}</h2>
    </div>
  );
}
