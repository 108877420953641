import React, { useState } from 'react';
import Modal from '../components/Modal/Modal';
import NavbarOrder from '../components/Web/Orders/Navbar/NavbarOrder';
import Footer from '../components/Web/Orders/Footer/Footer';

export default function LayoutBasic(props) {
  const { children } = props;

  return (
    <div
      style={{
        minHeight: '100vh',
        paddingBottom: '60px',
        position: 'relative',
      }}
    >
      <NavbarOrder />
      {children}
      <Footer />
    </div>
  );
}
