import React from 'react';

import { Navbar, Container, Nav } from 'react-bootstrap';
import Logo from '../../../assets/img/logo/logo-no-rotulo.png';

import './MenuTop.scss';

export default function MenuTop(props) {
  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/elpauporcjaris/dashboard">
          <img
            alt=""
            src={Logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Administración
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/elpauporcjaris/dashboard">Dashboard</Nav.Link>

            <Nav.Link href="/elpauporcjaris/pedidos">Pedidos</Nav.Link>
            <Nav.Link href="/elpauporcjaris/productos">Productos</Nav.Link>
            <Nav.Link href="/elpauporcjaris/reclamaciones">
              Reclamaciones
            </Nav.Link>
            <Nav.Link href="/elpauporcjaris/usuarios">Usuarios</Nav.Link>
            <Nav.Link href="/elpauporcjaris/ajustes">Ajustes</Nav.Link>

            <Nav.Link href="/carta">Ver la web</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
