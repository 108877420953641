import { API_VERSION, BASE_PATH } from './config';

export async function signInAdminApi(data) {
  const url = `${BASE_PATH}/${API_VERSION}/sign-in-admin`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://elpollopaulino.netlify.app',
      Origin: 'https://elpollopaulino.netlify.app', // or the URL of your app
    },
  };

  return fetch(url, params)
    .then((res) => res.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((response) => {
      if (!response.code || response.code !== 200) {
        return { ok: false, message: 'Acceso denegado' };
      } else {
        localStorage.setItem('ADMIN_ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('ADMIN_REFRESH_TOKEN', response.refreshToken);
        return response;
      }
    });
}
