import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Claim.scss';

export default function ClaimDelivered() {
  return (
    <div className="claim-delivered">
      <h2>Incidencia registrada con éxito</h2>
      <p>
        Sabemos lo importante que es para usted contar con un servicio confiable
        y de calidad, y nos sentimos muy decepcionados de no haber cumplido con
        sus expectativas en esta ocasión.
      </p>{' '}
      <p>
        Le aseguro que trabajamos día a día para mejorar nuestro servicio y
        evitar errores como este en el futuro.
      </p>
      <p>
        Valoramos mucho su confianza en nosotros y queremos hacer todo lo
        posible para ganárnosla de nuevo. Por favor, no dude en ponerse en
        contacto con nosotros si tiene alguna pregunta o inquietud.
      </p>
      <Link to="/perfil">
        <Button variant="warning">Volver a mis pedidos</Button>
      </Link>
    </div>
  );
}
