import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import LoginForm from '../../../components/Admin/LoginForm/LoginForm';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { getAccessTokenApi } from '../../../api/auth';

import Logo from '../../../assets/img/logo/logo-rotulo.png';

import './SignIn.scss';

export default function SignIn() {
  const { user, isLoading } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!getAccessTokenApi()) {
      navigate('/elpauporcjaris');
    }
  }, [isLoading, user, navigate]);

  return (
    <div className="sign-in-container">
      <div className="sign-in-container__content">
        <div className="logo">
          <img src={Logo} alt="Logo el pollo paulino" />
        </div>
        <LoginForm />
      </div>
    </div>
  );
}
