import React, { useEffect, useState } from 'react';
import { Card, Row, Col, ListGroup, Button, Collapse } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { getUserOrdersApi } from '../../../../api/order';
import { getUserInfo } from '../../../../api/user';
import useAuth from '../../../../hooks/useAuth';

import './Profile.scss';

export default function Profile(props) {
  const [orders, setOrders] = useState(null);
  const { isLoading, user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user && !isLoading) {
      navigate('/carta');
    }
  }, [isLoading, user, navigate]);

  useEffect(() => {
    if (orders === null)
      getUserOrdersApi().then((response) => {
        setOrders(response);
      });
  });

  return (
    <div className="profile-container">
      {/* <div className="personal-info">
        <Card>
          <Card.Header>Información Personal</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={6}>
                <Card.Text>Nombre</Card.Text>
                <Card.Text>{user.name && user.name}</Card.Text>
              </Col>
              <Col xs={6}>
                <Card.Text>Apellido</Card.Text>
                <Card.Text>{user.lastName}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Card.Text>Email</Card.Text>
                <Card.Text>{user.email}</Card.Text>
              </Col>
              <Col xs={6}>
                <Card.Text>Tel.</Card.Text>
                <Card.Text>{user.phoneNumber}</Card.Text>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Card.Text>C.P.</Card.Text>
                <Card.Text>{user.postalCode}</Card.Text>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div> */}
      <div className="order-history">
        <Card>
          <Card.Header>Historial de pedidos</Card.Header>
          <ListGroup>
            <Card.Body>
              {orders &&
                orders.map((order, index) => (
                  <ListGroup.Item key={index}>
                    <OrderItem order={order} />
                  </ListGroup.Item>
                ))}
              {orders === null ||
                (orders.length === 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',

                      textAlign: 'center',
                    }}
                  >
                    <h6>¡Aún no has realizado ningún pedido!</h6>
                    <Link to="/carta">
                      <Button>Ver la carta</Button>
                    </Link>
                  </div>
                ))}
            </Card.Body>
          </ListGroup>
        </Card>
      </div>
    </div>
  );
}

function OrderItem(props) {
  const { order } = props;
  const [open, setOpen] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  return (
    <div className="product-cart">
      <div className="product-cart-information">
        <h6>Tu pedido del {new Date(order.orderDate).toLocaleDateString()}</h6>
        <div>
          <br />
          <div
            onClick={() => setOpenInfo(!openInfo)}
            style={{ cursor: 'pointer' }}
          >
            Detalles{'  '}
            <FontAwesomeIcon icon={openInfo ? faChevronUp : faChevronDown} />
          </div>
          <Collapse in={openInfo}>
            <p>
              Realizado el {new Date(order.createdAt).toLocaleDateString()} a
              las {new Date(order.createdAt).toLocaleTimeString()}
              <br />
              Fecha de entrega: {new Date(order.orderDate).toLocaleDateString()}
              <br />
              Hora de entrega: {new Date(order.orderDate).toLocaleTimeString()}
              <br />
              Estado:{' '}
              {order.orderStatus === 'INCIDENCIA' && (
                <p style={{ color: 'red' }}>{order.orderStatus}</p>
              )}
              {order.orderStatus !== 'INCIDENCIA' && (
                <div>{order.orderStatus}</div>
              )}
              <br />
              Método de pago:{' '}
              {order.paymentMethod === 0 ? 'Pago en tienda' : 'Pago online'}
              <br />
              Indicaciones:{' '}
              {order.indications !== null
                ? order.indications
                : 'Sin indicaciones'}
            </p>
          </Collapse>
          <br />
          <div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
            Productos{'  '}
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </div>
          <div style={{ fontSize: '14px', color: 'lightgray' }}>
            <Collapse in={open}>
              <ol>
                {order.items.map((product) => (
                  <li>
                    {product.name} <br />
                    Precio: {product.price}€<br />
                    Cantidad: {product.quantity}
                  </li>
                ))}
              </ol>
            </Collapse>
          </div>
          <br />
          Total {order.total}€
          {order.orderStatus === 'ENTREGADO' && (
            <div>
              <Link to={`/incidencia/${order._id}`}>
                <p style={{ color: '#007bff', fontSize: 'small' }}>
                  Informar de un incidente
                </p>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
