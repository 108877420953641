import React, { useState, useEffect, useCallback } from 'react';
import io from 'socket.io-client';
import { Button } from 'react-bootstrap';
import notificationSound from '../../../assets/sounds/bell.mp3';
import popSound from '../../../assets/sounds/pop.mp3';
import reversePopSound from '../../../assets/sounds/reverse-pop.mp3';
import { getOrdersFilteredApi } from '../../../api/order';

import './OrderStream.scss';

export default function OrderStream(props) {
  const [orders, setOrders] = useState([]);
  const [soundOn, setSoundOn] = useState(false);
  const [noOrders, setNoOrders] = useState(false);
  const [deletedOrders, setDeletedOrders] = useState([]);
  const [prevOrder, setPrevOrder] = useState(null);
  const [socket, setSocket] = useState(null);
  //'http://localhost:3977'
  useEffect(() => {
    console.log('Initializing socket.');

    const socketInstance = io('https://elpollopaulino.herokuapp.com', {
      withCredentials: true,
      credentials: 'include',
    });
    setSocket(socketInstance);

    socketInstance.on('connect', () => {
      console.log('Connected to socket server:', socketInstance.id);
    });

    socketInstance.on('disconnect', (e) => {
      console.log('Disconnected from socket server:', e);
    });

    return () => {
      socketInstance.disconnect();
    };
  }, []);

  useEffect(() => {
    if (orders.length === 0 && deletedOrders.length === 0) {
      fetchOrders();
    }
  });

  const deleteOrder = (orderId) => {
    setOrders((prevOrders) => {
      const remainingOrders = prevOrders.filter(
        (order) => order._id !== orderId
      );
      const deletedOrder = prevOrders.find((order) => order._id === orderId);
      const deletedOrderIndex = prevOrders.findIndex(
        (order) => order._id === orderId
      );

      setDeletedOrders((prevDeletedOrders) => [
        ...prevDeletedOrders,
        { order: deletedOrder, index: deletedOrderIndex },
      ]);
      return remainingOrders;
    });
  };

  const restoreOrder = () => {
    if (deletedOrders.length > 0) {
      const lastDeletedOrder = deletedOrders[deletedOrders.length - 1].order;
      const lastDeletedOrderIndex =
        deletedOrders[deletedOrders.length - 1].index;

      // Check if the order is already in the list
      const orderExists = orders.some(
        (order) => order._id === lastDeletedOrder._id
      );
      if (!orderExists) {
        new Audio(reversePopSound).play();
        setOrders((prevOrders) => {
          const updatedOrders = [...prevOrders];
          updatedOrders.splice(lastDeletedOrderIndex, 0, lastDeletedOrder);
          return updatedOrders;
        });
      }

      setDeletedOrders((prevDeletedOrders) => prevDeletedOrders.slice(0, -1));
    }
  };

  const fetchOrders = useCallback(async () => {
    if (noOrders) return;
    try {
      const today = new Date();
      const dateFrom = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      const dateTo = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 1
      );

      const response = await getOrdersFilteredApi({
        dateFrom: dateFrom.toUTCString(),
        dateTo: dateTo.toUTCString(),
        orderStatus: 'PENDIENTE',
      });
      // Sort fetched orders by orderDate
      const sortedFetchedOrders = response.sort((a, b) => {
        const dateA = new Date(a.orderDate);
        const dateB = new Date(b.orderDate);
        return dateA - dateB;
      });
      if (response.length === 0) {
        setNoOrders(true);
      }
      setOrders((prevOrders) => {
        // Merge fetched orders with existing orders without duplicates
        const ordersById = prevOrders.reduce((acc, order) => {
          acc[order._id] = order;
          return acc;
        }, {});

        sortedFetchedOrders.forEach((fetchedOrder) => {
          if (!ordersById[fetchedOrder._id]) {
            ordersById[fetchedOrder._id] = fetchedOrder;
          }
        });

        return Object.values(ordersById).sort((a, b) => {
          const dateA = new Date(a.orderDate);
          const dateB = new Date(b.orderDate);
          return dateA - dateB;
        });
      });
    } catch (err) {
      console.log(err);
    }
  }, [noOrders]);

  useEffect(() => {
    if (!socket) return;
    // Listen for order changes
    console.log('Listening for orderChange');

    socket.on('orderChange', function (change) {
      const today = new Date();
      const orderDate = new Date(change.fullDocument.orderDate);
      console.log(change.fullDocument);
      if (
        today.getFullYear() === orderDate.getFullYear() &&
        today.getMonth() === orderDate.getMonth() &&
        today.getDate() === orderDate.getDate()
      ) {
        console.log('Order has orderDate for today');
        if (change.fullDocument !== prevOrder) {
          setPrevOrder(change.fullDocument);
          setOrders((prevOrders) => {
            // Check if the order already exists in the state
            const orderExists = prevOrders.some(
              (order) => order._id === change.fullDocument._id
            );
            if (orderExists) {
              return prevOrders;
            } else {
              new Audio(notificationSound).play();
              // Add the new order to the list of orders

              return [change.fullDocument, ...prevOrders];
            }
          });
        } else {
          return;
        }
      } else {
        console.log('Order does not have orderDate for today');
        return;
      }
    });
  }, [socket]);

  return (
    <div>
      <h1>Pedidos en tiempo real</h1>
      <Button onClick={() => setSoundOn(!soundOn)} disabled={soundOn}>
        {soundOn ? 'Sonido activado' : 'Activar sonido'}
      </Button>
      <Button onClick={restoreOrder} variant="success">
        Restaurar último pedido eliminado
      </Button>
      <Button onClick={fetchOrders} variant="warning">
        Obtener pedidos
      </Button>
      <div className="orders-container">
        {orders.map &&
          orders.map((order, index) => (
            <OrderItem
              key={order._id}
              order={order}
              deleteOrder={deleteOrder}
            />
          ))}
      </div>
    </div>
  );
}

function getRandomColor() {
  const colors = [
    '#f0e6ef',
    '#f9f9f9',
    '#e0f2f1',
    '#e5e8e8',
    '#f3e5f5',
    '#e8eaf6',
  ];
  return colors[Math.floor(Math.random() * colors.length)];
}

function OrderItem(props) {
  const { order, deleteOrder } = props;
  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsNew(true);
    }, 1500);
  });
  return (
    <div
      style={{}}
      key={order._id}
      className={`order ${isNew ? 'animated-bg' : ''}}`}
      onClick={() => {
        new Audio(popSound).play();
        deleteOrder(order._id);
      }}
    >
      <div>
        <h6>{new Date(order.orderDate).toLocaleTimeString()}</h6>
        <hr />
        <h4>{order.customerName}</h4>
        <h6>{order.phoneNumber}</h6>
        <hr />
        <div>
          <b>Productos:</b>
          {order.items &&
            order.items.map((item) => (
              <div key={`${order._id}${item._id}`}>
                <h6>{item.categoryName}</h6>
                <div>
                  {item.name} x {item.quantity}
                </div>
              </div>
            ))}
        </div>
        <hr />
        <div>
          <b>Fecha creación:</b>{' '}
          {new Date(order.createdAt).toLocaleDateString()}{' '}
          {new Date(order.createdAt).toLocaleTimeString()}{' '}
        </div>
        <div>
          <b>Entrega:</b> {new Date(order.orderDate).toLocaleDateString()}{' '}
          {new Date(order.orderDate).toLocaleTimeString()}{' '}
        </div>
        <div>{order.indications}</div>
        <div>
          <b>Total:</b> {order.total}€
        </div>
      </div>
    </div>
  );
}
