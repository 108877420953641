import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Button, Row, Col, FormControl, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { getOrderApi, setOrderToClaim } from '../../../../api/order';
import { addClaimApi } from '../../../../api/claim';

import './Claim.scss';

export default function Claim() {
  let { orderId } = useParams();
  const [claimData, setClaimData] = useState({
    userId: '',
    orderId: '',
    claimType: '',
    description: '',
  });
  const [order, setOrder] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [sendingClaim, setSendingClaim] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (order === null)
      getOrderApi(orderId)
        .then((response) => {
          setOrder(response);
          setClaimData({
            ...claimData,
            orderId: response._id,
            userId: response.userId,
          });
          if (response.orderStatus === 'INCIDENCIA') {
            navigate('/perfil');
          }
        })
        .catch((e) => {
          console.log(e);
          navigate('/perfil');
        });
  }, [orderId, claimData, navigate, order]);

  const handleOnChange = (e) => {
    e.preventDefault();
    setClaimData({ ...claimData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingClaim(true);
    if (order) {
      if (claimData.description.length > 5) {
        addClaimApi(claimData).then((response) => {
          setOrderToClaim(claimData.orderId)
            .then((response) => {
              navigate('/incidencia-realizada');
            })
            .catch((e) => {
              setError(
                'Lo sentimos, ha ocurrido un error al registar su incidencia. Inténtalo de nuevo o llámenos por teléfono.'
              );
            });
        });
      } else {
        setSendingClaim(false);
        setError('Por favor, introduzca más detalles sobre la incidencia');
      }
    } else {
      navigate('/perfil');
    }
  };

  return (
    <div className="claim-form-container">
      <h1>Reportar incidencia</h1>
      <Form onChange={handleOnChange} onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Form.Label column>Tipo de incidencia</Form.Label>
            <Form.Control as="select" className="mb-3" name="claimType">
              <option>-- Selecciona el tipo de incidencia --</option>
              <option value="Calidad o la frescura de la comida">
                Calidad o la frescura de la comida
              </option>
              <option value="Demoras en la preparación o entrega de la comida">
                Demoras en la preparación o entrega de la comida
              </option>
              <option value="Errores en el pedido o en la facturación">
                Errores en el pedido o en la facturación
              </option>
              <option value="Problemas con el servicio al cliente">
                Problemas con el servicio al cliente
              </option>
              <option value="Otros">Otros</option>
            </Form.Control>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Label>Cuéntanos qué ha pasado</Form.Label>
            <FormControl
              name="description"
              placeholder="Especifica artículos si procede"
              as="textarea"
              rows="3"
              className="mb-3"
            />
          </Col>
        </Row>

        <Row>
          <p style={{ color: 'red' }}>{error}</p>
        </Row>
        <Row>
          <Col>
            <Button disabled={sendingClaim} type="submit">
              Enviar incidencia
            </Button>
          </Col>
        </Row>
      </Form>
      <br />
      {order && (
        <div className="product-cart-information">
          <h6 onClick={() => setOpen(!open)}>
            Tu pedido{' '}
            <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
          </h6>
          <Collapse in={open}>
            <div>
              <p>
                Realizado el {new Date(order.createdAt).toLocaleDateString()} a
                las {new Date(order.createdAt).toLocaleTimeString()}
                <br />
                Fecha de entrega:{' '}
                {new Date(order.orderDate).toLocaleDateString()}
                <br />
                Hora de entrega:{' '}
                {new Date(order.orderDate).toLocaleTimeString()}
                <br />
                Estado: {order.orderStatus}
                <br />
                Método de pago:{' '}
                {order.paymentMethod === 0 ? 'Pago en tienda' : 'Pago online'}
                <br />
                Indicaciones:{' '}
                {order.indications !== null
                  ? order.indications
                  : 'Sin indicaciones'}
              </p>
              <div
                style={{
                  cursor: 'pointer',
                  fontSize: '14px',
                  color: 'lightgray',
                }}
              >
                Productos{'  '}
              </div>
              <div style={{ fontSize: '14px', color: 'lightgray' }}>
                <ol>
                  {order.items.map((product) => (
                    <li>
                      {product.name} <br />
                      Precio: {product.price}€<br />
                      Cantidad: {product.quantity}
                    </li>
                  ))}
                </ol>
                Total {order.total}€
              </div>
              <br />
            </div>
          </Collapse>
        </div>
      )}
    </div>
  );
}
