import React from 'react';

import { default as HomeComponent } from '../../../components/Admin/Home';

export default function Home() {
  return (
    <div>
      <HomeComponent />
    </div>
  );
}
