import React, { useState } from 'react';
import { Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { addCategoryApi } from '../../../../api/category';

export default function AddCategory(props) {
  const { setRefreshProducts, closeModal } = props;

  const [categoryData, setCategoryData] = useState({
    name: '',
    index: 99999,
    description: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.name !== 'shouldBeSeenInHomepage') {
      setCategoryData({
        ...categoryData,
        [e.target.name]: e.target.value,
      });
    } else {
      setCategoryData({
        ...categoryData,
        [e.target.name]: e.target.checked,
      });
    }
  };
  const updateCategory = (e) => {
    e.preventDefault();

    addCategoryApi(categoryData).then((response) => {
      setRefreshProducts(true);
      closeModal();
    });
  };

  return (
    <Form onChange={handleChange} onSubmit={updateCategory}>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Nombre de categoría"
            className="mb-3"
          >
            <Form.Control name="name" value={categoryData.name} />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Descripción"
            className="mb-3"
          >
            <Form.Control
              as="textarea"
              name="description"
              defaultValue={categoryData.description}
              style={{ minHeight: '100px' }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            defaultChecked={categoryData.shouldBeSeenInHomepage}
            onChange={(e) =>
              setCategoryData({
                ...categoryData,
                shouldBeSeenInHomepage: e.target.checked,
              })
            }
            checked={categoryData.shouldBeSeenInHomepage}
            name="shouldBeSeenInHomepage"
            label="Visible en la página principal"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="submit" style={{ width: '100%' }}>
            Crear categoría
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
