import React from 'react';

export default function LayoutBasic(props) {
  const { children } = props;

  return <div>{children}</div>;

  /* return (
    <Layout>
      <Layout>
        <Content>{children}</Content>
        <Footer>Pablo Osés</Footer>
      </Layout>
    </Layout>
  ); */
}
