import React from 'react';
import Header from './Header';
import Nav from '../Nav';

import './Landing.scss';

const logo = require('../../../../assets/img/logo/logo-no-rotulo.png');
export default function Landing() {
  return (
    <div className="landing-container">
      <div className="landing-container__items">
        <Logo />
        <Header />
        <Nav />
      </div>
    </div>
  );
}

function Logo() {
  return (
    <div
      className="landing-container__items__logo"
      style={{
        height:
          window.innerWidth > 1023 ? `${window.innerWidth * 0.075}px` : 'auto',
      }}
    >
      <img src={logo} alt="logo paulino" />
    </div>
  );
}
