import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function SignUpSuccess(props) {
  return (
    <>
      <div className="empty-cart">
        <h3>¡Registro realizado con éxito!</h3>
        <p>
          Revisa tu bandeja de entrada para encontrar el correo electrónico para
          confirmar tu cuenta
        </p>
        <br />

        <div className="show-menu-button">
          <Link to="/carta">
            <Button variant="warning" size="md">
              Ver la carta
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
