import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
  FormLabel,
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import {
  getProductImageApi,
  uploadProductImage,
  updateProductApi,
} from '../../../../api/product';

export default function EditProduct(props) {
  const {
    product,
    categoryId,
    setRefreshProducts,
    closeModal,
    setUpdateImage,
  } = props;

  const [image, setImage] = useState(product.image || null);

  const [productData, setProductData] = useState({
    name: '',
    description: '',
    image: null,
    price: 0,
    active: false,
    phoneOnly: false,
    variant: [],
  });

  useEffect(() => {
    if (product !== null) {
      setProductData({
        name: product.name,
        description: product.description,
        image: product.image,
        price: product.price,
        active: product.active,
        phoneOnly: product.phoneOnly,
        variant: product.variant,
      });
    }
  }, [product]);

  let variants = product.variant;

  const handleCheckChange = (e) => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.checked,
    });
  };

  const handleVariantChange = (e, index) => {
    e.preventDefault();
    const newVariants = [...variants];
    newVariants[index][e.target.name] = e.target.value;
    setProductData({ ...productData, variant: newVariants });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setProductData((prevProductData) => ({
      ...prevProductData,
      [e.target.name]: e.target.value,
    }));

    if (variants.length > 0) {
      //setProductData({ ...productData, price: productData.variant[0].price });
    }
  };

  const updateProduct = (e) => {
    e.preventDefault();

    updateProductApi(productData, categoryId, product._id).then((response) => {
      response.result.products.forEach((e) => {
        if (image !== product.image) {
          uploadProductImage(categoryId, product._id, image).then(
            (response) => {
              setUpdateImage(true);
              setRefreshProducts(true);
              closeModal();
            }
          );
        } else {
          setRefreshProducts(true);
          closeModal();
        }
      });
    });
  };

  return (
    <Form onSubmit={updateProduct}>
      <Row style={{ justifyContent: 'center' }}>
        <UploadImage setImage={setImage} image={image} />
      </Row>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Nombre"
            className="mb-3"
          >
            <Form.Control
              type="text"
              name="name"
              value={productData.name}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <FloatingLabel
              controlId="floatingInput"
              label="Precio"
              className="mb-3"
            >
              <Form.Control
                name="price"
                type="number"
                step="0.01"
                placeholder="Precio"
                onChange={handleChange}
                value={
                  productData.variant.length > 0
                    ? productData.variant[0].price
                    : productData.price
                }
              />
            </FloatingLabel>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormLabel>Descripción</FormLabel>
          <Form.Control
            as="textarea"
            placeholder="Descripción"
            name="description"
            style={{ minHeight: '100px' }}
            onChange={handleChange}
            value={productData.description}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="add-variant-button"
            variant="outline-primary"
            onClick={() => {
              variants.push({ name: '', price: 0 });
              setProductData({ ...productData, variant: variants });
            }}
          >
            + Añadir variante
          </Button>
          {variants.length > 0 &&
            productData.variant.map((variant, index) => {
              return (
                <div className="food-variation-field">
                  <Row>
                    <Col xs={6}>
                      <Form.Control
                        type="text"
                        name="type"
                        placeholder="Nombre variante"
                        value={variant.type}
                        onChange={(e) => {
                          e.preventDefault();
                          handleVariantChange(e, index);
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="price"
                          value={variant.price}
                          placeholder="Precio variante"
                          onChange={(e) => {
                            e.preventDefault();
                            handleVariantChange(e, index);
                          }}
                        />
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setProductData({
                            ...productData,
                            variant: variants,
                          });
                        }}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </Col>
      </Row>
      <Row>
        <Col>
          <div style={{ margin: '10px 0 10px 0' }}>
            <Form.Check
              id="checkbox"
              type="checkbox"
              label="Activo"
              name="active"
              size="lg"
              onChange={handleCheckChange}
              checked={productData.active}
            />
          </div>
          <div style={{ margin: '10px 0 10px 0' }}>
            <Form.Check
              id="checkbox"
              type="checkbox"
              label="Sólo por teléfono"
              name="phoneOnly"
              size="lg"
              onChange={handleCheckChange}
              checked={productData.phoneOnly}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <Button type="submit" style={{ width: '100%' }}>
              Actualizar producto
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </Form>
  );
}

function UploadImage(props) {
  const { image, setImage } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [hasBeenChanged, setHasBeenChanged] = useState(false);

  useEffect(() => {
    if (
      (avatarUrl === null && image !== null && typeof avatarUrl !== 'object') ||
      (!hasBeenChanged && image !== null)
    ) {
      console.log(image);
      getProductImageApi(image)
        .then((response) => {
          setAvatarUrl(response);
        })
        .catch((e) => {});
    }
  });

  useEffect(() => {
    console.log('Setting image...');
    if (image) {
      if (image.preview) {
        setAvatarUrl(image.preview);
      } else {
        setAvatarUrl(image);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [image]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      try {
        setHasBeenChanged(true);
        const file = acceptedFiles[0];
        setImage({ file, preview: URL.createObjectURL(file) });
      } catch (error) {}
    },
    [setImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      style={{
        width: '200px',

        margin: '20px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        border: '2px dashed #cecece',

        overflow: 'hidden',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <img style={{ width: '100%' }} src={image} alt="" />
      ) : (
        <img
          style={{ width: '180px' }}
          src={avatarUrl ? avatarUrl : ''}
          alt="imagen de producto"
        />
      )}
    </div>
  );
}
