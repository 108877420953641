import { BASE_PATH, API_VERSION } from './config';
import { getAdminAccessTokenApi } from './auth';

export async function addTimeApi(time) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/add-time`;

  const params = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(time),
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getTimesApi() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const url = `${BASE_PATH}/${API_VERSION}/get-times`;

  const params = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getConfigApi() {
  const token = localStorage.getItem('ACCESS_TOKEN');
  const url = `${BASE_PATH}/${API_VERSION}/get-config`;

  const params = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function setAcceptingOrdersApi(acceptingOrders) {
  const token = localStorage.getItem('ADMIN_ACCESS_TOKEN');
  const url = `${BASE_PATH}/${API_VERSION}/set-accepting-orders/${acceptingOrders}`;

  const params = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
