import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

import './ResetPassword.scss';

const accountActivatedPicture = require('../../../../assets/img/png/account-activated.png');

export default function CheckOutCompleted() {
  const { userId } = useParams();
  return (
    <>
      {!userId && (
        <div className="reset-password-container">
          <br />
          <h3>¡Restablecimiento de contraseña enviado!</h3>{' '}
          <img
            className="password-image"
            src={accountActivatedPicture}
            alt="el pollo paulino encargo realizado"
          />
          <br />
          <p>
            Revisa tu bandeja de entrada para seguir con el restablecimiento de
            la contraseña.
          </p>
          <p>Si no has recibido ningún correo, busca en la carpeta de 'spam'</p>
          <br />
          <br />
          <Link to="/">
            <Button variant="warning" size="md">
              Volver a la página principal
            </Button>
          </Link>
        </div>
      )}
      {userId && (
        <div className="reset-password-container">
          <br />
          <h3>¡Contraseña cambiada con éxito!</h3> <br />
          <br />
          <Link to="/carta">
            <Button variant="warning" size="md">
              Volver a la carta
            </Button>
          </Link>
        </div>
      )}
    </>
  );
}
