import { BASE_PATH, API_VERSION } from './config';
import { getAccessTokenApi } from './auth';
import jwtDecode from 'jwt-decode';

export async function getCartApi() {
  const token = await getAccessTokenApi();
  const decodedToken = await jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/${decodedToken.id}/get-cart/`;
  const params = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getProductApi(product) {
  const token = await getAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/${product.categoryId}/get-product/${product._id}`;
  const params = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function addProductToCartApi(product) {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/${decodedToken.id}/add-to-cart/${product.categoryId}/${product._id}/${product.quantity}/${product.variantIndex}`;

  const params = {
    method: 'PUT',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function removeProductFromCartApi(productId) {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/${decodedToken.id}/delete-from-cart/${productId}`;

  const params = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function dropCartApi() {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/${decodedToken.id}/drop-cart`;

  const params = {
    method: 'DELETE',
    credentials: 'include',
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
