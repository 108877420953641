import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListUl,
  faTriangleExclamation,
  faCartShopping,
  faUser,
  faGear,
  faG,
} from '@fortawesome/free-solid-svg-icons';

import './Home.scss';

export default function Home() {
  return (
    <div className="dashboard-container">
      <h2>Bienvenido</h2>
      <div className="dashboard-menu-container">
        <MenuItem
          icon={faCartShopping}
          text="Pedidos"
          url="/elpauporcjaris/pedidos"
        />
        <MenuItem
          icon={faTriangleExclamation}
          text="Reclamaciones"
          url="/elpauporcjaris/reclamaciones"
        />
        <MenuItem
          icon={faListUl}
          text="Productos"
          url="/elpauporcjaris/productos"
        />
        <MenuItem
          icon={faUser}
          text="Usuarios"
          url="/elpauporcjaris/usuarios"
        />
        <MenuItem
          icon={faGear}
          text="Configuración"
          url="/elpauporcjaris/ajustes"
        />
      </div>
    </div>
  );
}

function MenuItem(props) {
  const { icon, text, url } = props;

  return (
    <Link to={url}>
      <div className="dashboard-menu-item">
        <div className="dashboard-menu-item-icon">
          <FontAwesomeIcon size="4x" icon={icon} style={{}} />
        </div>
        <div className="dashboard-menu-item-text">{text}</div>
      </div>
    </Link>
  );
}
