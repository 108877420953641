import React from 'react';
import { Link } from 'react-router-dom';

import './MakeOrder.scss';

import DeliveryIcon from '../../../../assets/icons/delivery-icon.svg';
import StoreIcon from '../../../../assets/icons/store-icon.svg';

export default function MakeOrder(props) {
  return (
    <div className="make-order">
      <div className="make-order__frame">
        <div className="make-order__frame__text">
          <h2>Haz tu pedido</h2>
          <h3>y deja que nos ocupemos de cocinar hoy</h3>
        </div>
        <div className="make-order__frame__buttons">
          <IconButton
            text="a domicilio"
            icon={DeliveryIcon}
            url="https://glovoapp.com/es/es/pamplona/paulino-pamplona/"
          />
          <div className="custom-button">
            <Link to="/carta">
              <div className="custom-button__frame">
                <div className="custom-button__frame__icon">
                  <img src={StoreIcon} alt="icono tienda" />
                </div>
              </div>
            </Link>
            <p>recoger</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function IconButton(props) {
  const { text, icon, url } = props;
  return (
    <div className="custom-button">
      <a href={url} target="blank">
        <div className="custom-button__frame">
          <div className="custom-button__frame__icon">
            <img src={icon} alt={text} />
          </div>
        </div>
      </a>
      <p>{text}</p>
    </div>
  );
}
