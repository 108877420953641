import React, { useState, useEffect, createContext } from 'react';
import jwtDecode from 'jwt-decode';
import {
  refreshAccessToken,
  isTokenExpired,
  logout,
  refreshAdminAccessToken,
} from '../api/auth';

export const AuthContext = createContext();

export default function AuthProvider(props) {
  const { children } = props;
  const [user, setUser] = useState({
    user: null,
    isLoading: true,
  });

  useEffect(() => {
    checkUserLogin(setUser);
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}

async function checkUserLogin(setUser) {
  try {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    const adminAccessToken = localStorage.getItem('ADMIN_ACCESS_TOKEN');

    if (!accessToken && !adminAccessToken) {
      logout();
      setUser({
        isLoading: false,
        data: null,
      });
    } else {
      let newToken = null;

      if (accessToken && isTokenExpired(accessToken)) {
        newToken = await refreshAccessToken();
      }

      if (adminAccessToken && isTokenExpired(adminAccessToken)) {
        newToken = await refreshAdminAccessToken();
      }

      if (newToken) {
        checkUserLogin(setUser);
      } else {
        const tokenToUse = adminAccessToken ? adminAccessToken : accessToken;
        setUser({
          isLoading: false,
          user: jwtDecode(tokenToUse),
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}
