import React, { useEffect, useState } from 'react';

import { Button, ListGroup } from 'react-bootstrap';
import {
  addProductToCartApi,
  removeProductFromCartApi,
} from '../../../../../api/cart';
import { faTrashCan, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './ProductTable.scss';

export default function ProductsTable(props) {
  const { products, setRefreshCartItems, setProducts, sendingOrder } = props;
  const [totalPrice, setTotalPrice] = useState(0);
  const [refreshTotal, setRefreshTotal] = useState(false);
  const handleDelete = (productId, setHandlingDelete) => {
    setHandlingDelete(true);
    removeProductFromCartApi(productId).then((response) => {
      setHandlingDelete(false);
      setRefreshCartItems(true);
    });
  };

  useEffect(() => {
    let price = 0;
    setRefreshTotal(false);
    products.forEach((product) => {
      product.variant.forEach((variant) => {
        price += variant.quantity ? variant.price * variant.quantity : 0;
      });
    });
    setTotalPrice(price);
  }, [products, refreshTotal]);

  return (
    <>
      {!sendingOrder && (
        <div>
          <ListGroup>
            {products.map((product, index) => (
              <ListGroup.Item key={product._id}>
                <ProductRow
                  key={`${product._id}${index}`}
                  product={product}
                  products={products}
                  setProducts={setProducts}
                  handleDelete={handleDelete}
                  setRefreshTotal={setRefreshTotal}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div style={{ textAlign: 'right', marginRight: '10px' }}>
            <h5>Total {totalPrice}€</h5>
          </div>
        </div>
      )}
    </>
  );
}

function ProductRow(props) {
  const { product, products, handleDelete, setRefreshTotal, setProducts } =
    props;
  const [handlingDelete, setHandlingDelete] = useState(false);
  const [productData, setProductData] = useState(product);
  const [subtotal, setSubtotal] = useState(0);
  const [updatingProduct, setUpdatingProduct] = useState(false);

  const refreshProducts = () => {
    let arr = [];
    products.forEach((element) => {
      if (product._id === element._id) {
        arr.push(productData);
      } else {
        arr.push(element);
      }
    });
    setProducts(arr);
  };

  useEffect(() => {
    refreshProducts();
    getPrice(productData.variant);
  }, [subtotal, productData]);

  const updateQuantity = (quantity, variantId) => {
    setUpdatingProduct(true);
    let newProduct = productData;
    let totalQuantity = 0;
    newProduct.variant.forEach((variant, index) => {
      if (variant._id === variantId) {
        variant.quantity = variant.quantity + quantity;

        setProductData(newProduct);
        refreshProducts();
        newProduct.variantIndex = index;
        newProduct.quantity = quantity;
        addProductToCartApi(newProduct).then((response) => {
          setUpdatingProduct(false);
          setRefreshTotal(true);
          getPrice(productData.variant);
        });
      }
      totalQuantity += newProduct.quantity;
    });
  };

  const getPrice = (variants) => {
    let price = 0;
    variants.forEach((element) => {
      price += element.quantity > 0 ? element.quantity * element.price : 0;
    });
    setSubtotal(price);
    if (price === 0) {
      handleDelete(product._id, setHandlingDelete);
    }
  };

  return (
    <div className="product-cart">
      <div className="product-cart-information">
        <h6>{product.name}</h6>
        <div>
          {productData.variant.map((variant, index) => {
            if (variant.quantity) {
              return (
                <p key={variant._id}>
                  <b>{variant.type}</b>
                  <br />
                  Precio: {variant.price}€
                  <br />
                  Cantidad:
                  <br />
                  <span>
                    <Button
                      onClick={() => {
                        updateQuantity(-1, variant._id);
                      }}
                      disabled={updatingProduct}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </span>
                  <span style={{ margin: '0 10px 0 10px', fontSize: '16px' }}>
                    {variant.quantity}
                  </span>
                  <span>
                    <Button
                      onClick={() => {
                        updateQuantity(1, variant._id);
                      }}
                      disabled={updatingProduct}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  </span>
                </p>
              );
            } else {
              return <></>;
            }
          })}
          <p>Subtotal: {subtotal}€</p>
        </div>
      </div>
      <div className="product-cart-buttons">
        <Button
          variant="danger"
          onClick={() => {
            handleDelete(product._id, setHandlingDelete);
          }}
          disabled={handlingDelete}
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </Button>
      </div>
    </div>
  );
}
