import React, { useRef, useEffect, useState } from 'react';
import { Table, Button, ButtonGroup, Accordion } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import AddProduct from '../AddProduct/AddProduct';
import EditCategory from '../EditCategory/EditCategory';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import {
  getProductImageApi,
  deleteProductApi,
  updateProductApi,
} from '../../../../api/product';

import './ListProducts.scss';
import { deleteCategoryApi, updateCategoryApi } from '../../../../api/category';
import EditProduct from '../EditProduct/EditProduct';

export default function ListProducts(props) {
  const {
    setUpdateActiveProducts,
    categories,
    setRefreshProducts,
    handleClose,
    setModalInfo,
  } = props;
  let categoryCount = 0;

  const dragItem = useRef();
  const dragOverItem = useRef();

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(categories);
  }, [categories]);

  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...list];

    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems);
    list.forEach((category, index) => {
      updateCategoryApi({ index: index }, category._id).then(() => {});
    });
  };
  return (
    <Accordion defaultActiveKey={[]} alwaysOpen>
      {list.map((category, index) => {
        categoryCount++;
        return (
          <div
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            draggable
          >
            <Accordion.Item eventKey={categoryCount} key={categoryCount}>
              <Accordion.Header
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                {category.name}
                {category.shouldBeSeenInHomepage ? (
                  <div
                    style={{
                      fontSize: '10px',
                      marginLeft: '20px',
                      border: '1px solid green',
                      borderRadius: '5px',
                      padding: '5px',
                    }}
                  >
                    Visible en página principal
                  </div>
                ) : (
                  ''
                )}
              </Accordion.Header>
              <Accordion.Body>
                <ButtonGroup>
                  <Button
                    variant="outline-warning"
                    onClick={() => {
                      setModalInfo({
                        title: `Editar ${category.name}`,
                        body: (
                          <EditCategory
                            closeModal={handleClose}
                            category={category}
                            setRefreshProducts={setRefreshProducts}
                          />
                        ),
                        show: true,
                        wide: true,
                      });
                    }}
                  >
                    Editar categoría
                  </Button>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      setModalInfo({
                        title: `Eliminar ${category.name}`,
                        body: (
                          <DeleteCategory
                            categoryId={category._id}
                            setRefreshProducts={setRefreshProducts}
                            closeModal={handleClose}
                          />
                        ),
                        show: true,
                        wide: true,
                      });
                    }}
                  >
                    Eliminar categoría
                  </Button>
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      setModalInfo({
                        title: `Añadir producto a ${category.name}`,
                        body: (
                          <AddProduct
                            categoryId={category._id}
                            setRefreshProducts={setRefreshProducts}
                            closeModal={handleClose}
                          />
                        ),
                        show: true,
                        wide: true,
                      });
                    }}
                  >
                    Añadir producto
                  </Button>
                </ButtonGroup>
                <Table striped bordered hover size="md" responsive="sm">
                  <thead>
                    <tr>
                      <th>Imagen</th>
                      <th>Nombre</th>
                      <th>Precio</th>
                      <th>Descripción</th>
                      <th>Activo</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.products ? (
                      category.products.map((e) => {
                        return (
                          <ProductRow
                            key={e._id}
                            e={e}
                            handleDelete={() => {
                              setModalInfo({
                                title: `Eliminar ${e.name}`,
                                body: (
                                  <DeleteProduct
                                    product={e}
                                    categoryName={category.name}
                                    productName={e.name}
                                    categoryId={category._id}
                                    setRefreshProducts={setRefreshProducts}
                                    closeModal={handleClose}
                                  />
                                ),
                                show: true,
                                wide: true,
                              });
                            }}
                            setModalInfo={setModalInfo}
                            handleClose={handleClose}
                            categoryId={category._id}
                            setRefreshProducts={setRefreshProducts}
                            setUpdateActiveProducts={setUpdateActiveProducts}
                          />
                        );
                      })
                    ) : (
                      <div>No hay productos todavía</div>
                    )}
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        );
      })}
    </Accordion>
  );
}

function ProductRow(props) {
  const {
    e,
    handleDelete,
    setModalInfo,
    categoryId,
    setRefreshProducts,
    setUpdateActiveProducts,
    handleClose,
  } = props;
  const [productImage, setProductImage] = useState(null);
  const [updateImage, setUpdateImage] = useState(false);
  useEffect(() => {
    if ((e.image && productImage === null) || updateImage) {
      getProductImageApi(e.image).then((response) => {
        console.log(response.image);
        setProductImage(response);
        setUpdateImage(false);
      });
    }
  }, [updateImage, e.image, productImage]);

  return (
    <tr>
      <td>
        {productImage ? (
          <img
            style={{ width: '100px', height: 'auto', borderRadius: '10px' }}
            src={productImage}
            alt={e.name}
          />
        ) : (
          'Sin imagen'
        )}
      </td>
      <td>{e.name ? e.name : 'Sin nombre'}</td>
      <td>{e.price ? e.price : 'Sin precio'}</td>
      <td
        style={{
          width: 'fit-content',
          textOverflow: 'ellipsis',
        }}
      >
        {e.description ? e.description : 'Sin descripción'}
      </td>
      <td>
        <BootstrapSwitchButton
          checked={e.active}
          onChange={(checked) => {
            e.active = checked;
            updateProductApi(e, categoryId, e._id).then((response) => {
              setUpdateActiveProducts(true);
            });
          }}
        />
      </td>
      <td>
        <ButtonGroup>
          <Button
            variant="outline-warning"
            onClick={() =>
              setModalInfo({
                title: `Editar ${e.name}`,
                body: (
                  <EditProduct
                    product={e}
                    categoryId={categoryId}
                    setRefreshProducts={setRefreshProducts}
                    closeModal={handleClose}
                    setUpdateImage={() => setUpdateImage}
                  />
                ),
                show: true,
                wide: true,
              })
            }
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <Button variant="outline-danger" onClick={handleDelete}>
            <FontAwesomeIcon icon={faTrashCan} />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
}

function DeleteCategory(props) {
  const { categoryId, setRefreshProducts, closeModal } = props;

  const deleteCategory = () => {
    deleteCategoryApi(categoryId)
      .then((response) => {
        setRefreshProducts(true);
        closeModal();
      })
      .catch((err) => {
        console.error(err.message);
      });
  };

  return (
    <div>
      <p>
        ¿Estás seguro de que quieres eliminar la categoría y todos los productos
        que contiene?
      </p>
      <p>Esta acción no se puede deshacer.</p>
      <Button variant="success" onClick={deleteCategory}>
        Sí, estoy seguro
      </Button>
      <Button
        style={{ marginLeft: '10px' }}
        variant="danger"
        onClick={closeModal}
      >
        Mejor no
      </Button>
    </div>
  );
}
function DeleteProduct(props) {
  const { categoryId, setRefreshProducts, closeModal, categoryName, product } =
    props;

  const handleDelete = () => {
    deleteProductApi(product._id, categoryId).then((response) => {
      setRefreshProducts(true);
      closeModal();
    });
  };

  return (
    <div>
      <p>
        ¿Estás seguro de que quieres eliminar {product.name} de {categoryName}?
      </p>
      <p>Esta acción no se puede deshacer.</p>
      <Button variant="success" onClick={handleDelete}>
        Sí, estoy seguro
      </Button>
      <Button
        style={{ marginLeft: '10px' }}
        variant="danger"
        onClick={closeModal}
      >
        Mejor no
      </Button>
    </div>
  );
}
