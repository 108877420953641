import React, { useState } from 'react';

import Button from 'react-bootstrap/Button';

import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { signInAdminApi } from '../../../api/admin';

export default function LoginForm(props) {
  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState('');
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    e.preventDefault();
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value, //Con esto el resto de inputs
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await signInAdminApi(inputs)
      .then((res) => {
        if (res.code === 200) navigate('/elpauporcjaris/dashboard');
        else setErrorMsg(res.message);
      })
      .catch((err) => {
        navigate('/elpauporcjaris');
      })
      .then((response) => {});
  };

  return (
    <div className="login-form">
      <Form onChange={handleChange} onSubmit={handleSubmit} action="/pedido">
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Correo electrónico</Form.Label>
          <Form.Control
            autoComplete="email"
            type="email"
            placeholder="Introduce tu email"
            name="email"
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            autoComplete="current-password"
            name="password"
            type="password"
            placeholder="Contraseña"
          />
          <Form.Text className="text-muted"></Form.Text>
          <p style={{ color: 'red', fontSize: '10px' }}>{errorMsg}</p>
        </Form.Group>
        <Form.Group>
          <Button variant="warning" type="submit" style={{ width: '100%' }}>
            Acceder
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
}
