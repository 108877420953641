import React, { useState } from 'react';
import { Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap';
import { updateCategoryApi } from '../../../../api/category';

export default function EditCategory(props) {
  const { category, setRefreshProducts, closeModal } = props;

  const [categoryData, setCategoryData] = useState({
    name: category.name,
    index: category.index,
    description: category.description,
    shouldBeSeenInHomepage: category.shouldBeSeenInHomepage
      ? category.shouldBeSeenInHomepage
      : false,
  });

  const handleChange = (e) => {
    if (e.target.name !== 'shouldBeSeenInHomepage') {
      e.preventDefault();
      setCategoryData({
        ...categoryData,
        [e.target.name]: e.target.value,
      });
    } else {
      setCategoryData({
        ...categoryData,
        [e.target.name]: e.target.checked,
      });
    }
  };
  const updateCategory = (e) => {
    e.preventDefault();
    updateCategoryApi(categoryData, category._id).then((response) => {
      setRefreshProducts(true);
      closeModal();
    });
  };

  return (
    <Form onChange={handleChange} onSubmit={updateCategory}>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Nombre de categoría"
            className="mb-3"
          >
            <Form.Control
              name="name"
              value={categoryData.name}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Descripción"
            className="mb-3"
          >
            <Form.Control
              as="textarea"
              name="description"
              defaultValue={categoryData.description}
              style={{ minHeight: '100px' }}
              onChange={handleChange}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            style={{ margin: '0px 0 10px 0' }}
            id="checkbox"
            type="checkbox"
            size="lg"
            checked={categoryData.shouldBeSeenInHomepage}
            name="shouldBeSeenInHomepage"
            label="Visible en la página principal"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="submit" style={{ width: '100%' }}>
            Actualizar categoría
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
