import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { setAcceptingOrdersApi } from '../../../../api/settings';
import useConfig from '../../../../hooks/useConfig';

export default function AcceptingOrders(props) {
  const [isMakingRequest, setIsMakingRequest] = useState(false);
  const [error, setError] = useState('');
  const { acceptingOrders, isLoading } = useConfig();

  const handleAcceptingOrdersChange = () => {
    setIsMakingRequest(true);
    setAcceptingOrdersApi(!acceptingOrders)
      .then((response) => {
        setIsMakingRequest(false);
        window.location.reload();
      })
      .catch((e) => {
        setError(
          'Ha ocurrido un error al cambiar el estado de aceptar pedidos:' +
            e.message
        );
        setIsMakingRequest(false);
      });
  };

  return (
    <div>
      <h4>Pedidos web</h4>
      <p>
        Actualmente la web{' '}
        {acceptingOrders && !isLoading
          ? 'está aceptando '
          : 'no está aceptando '}
        pedidos
      </p>
      {acceptingOrders && !isLoading && (
        <Button
          variant="danger"
          disabled={isMakingRequest}
          onClick={handleAcceptingOrdersChange}
        >
          Dejar de aceptar pedidos
        </Button>
      )}
      {!acceptingOrders && !isLoading && (
        <Button
          variant="success"
          disabled={isMakingRequest}
          onClick={handleAcceptingOrdersChange}
        >
          Reanudar pedidos web
        </Button>
      )}
      <p style={{ color: 'red' }}>{error}</p>
    </div>
  );
}
