import React, { useState, useEffect } from 'react';

import ListUsers from '../../../components/Admin/Users/ListUsers';
import { Form, Row, Col } from 'react-bootstrap';
import { getUsersActiveApi } from '../../../api/user';
import { getAdminAccessTokenApi } from '../../../api/auth';
import { Button } from 'react-bootstrap';

export default function Users() {
  const [usersActive, setUsersActive] = useState(null);
  const [userLastName, setUserLastName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [filteredArray, setFilteredArray] = useState([]); // The original array
  const [userName, setUserName] = useState('');

  useEffect(() => {
    getUsersActiveApi(true).then((response) => {
      console.log(response);
      setUsersActive(response.users);
      setFilteredArray(response.users);
    });
  }, []);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    console.log(e.target);
    if (name === 'name') {
      setUserName(value);
    } else if (name === 'lastName') {
      setUserLastName(value);
    } else if (name === 'email') {
      setUserEmail(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilteredArray(
      usersActive.filter((item) => {
        return (
          item.name.includes(userName) &&
          item.lastName.includes(userLastName) &&
          item.email.includes(userEmail)
        );
      })
    );
  };

  return (
    <div>
      <h1>Usuarios</h1>
      <Form onChange={handleChange}>
        <Row>
          <Col>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="name" value={userName} />
          </Col>
          <Col>
            <Form.Label>Apellidos</Form.Label>
            <Form.Control type="text" name="lastName" value={userLastName} />
          </Col>
          <Col>
            <Form.Label>Email</Form.Label>
            <Form.Control type="text" name="email" value={userEmail} />
          </Col>
        </Row>
        <Button type="submit" variant="success" onClick={handleSubmit}>
          Aplicar filtros
        </Button>
      </Form>

      <ListUsers usersActive={filteredArray} />
    </div>
  );
}
