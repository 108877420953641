import React, { useState } from 'react';
import PhoneInput, {
  formatPhoneNumber,
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import {
  sendOTPVerification,
  sendPhoneVerification,
} from '../../../../api/user';

export default function PhoneVerification(props) {
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [otpcode, setOtpcode] = useState(null);
  const [otpInput, setOtpInput] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [error, setError] = useState('');
  const [hasBeenError, setHasBeenError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    if (e.target.name === 'otpcode') {
      setOtpcode(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (isPossiblePhoneNumber(phoneNumber) && !otpInput) {
      setError('');
      sendPhoneVerification(phoneNumber, userId)
        .then((res) => {
          setIsLoading(false);
          if (res.verification === 'pending') {
            setVerificationStatus(res);
            setOtpInput(true);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setError(
            'Ha ocurrido un error mientras intentábamos verificar tu número de teléfono. Por favor, inténtalo de nuevo.'
          );
          setHasBeenError(true);
        });
    } else if (otpInput) {
      const phoneNumberLocal = formatPhoneNumber(phoneNumber);
      sendOTPVerification(phoneNumber, phoneNumberLocal, otpcode, userId)
        .then((res) => {
          setVerificationStatus(res.verification);
          setIsLoading(false);
          setTimeout(() => {
            navigate('/carta');
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          setError(
            'Ha ocurrido un error mientras intentábamos verificar tu número de teléfono. Por favor, inténtalo de nuevo.'
          );
          setHasBeenError(true);
          setIsLoading(false);
        });
    } else {
      setError('Introduce un número de teléfono válido');
      setIsLoading(false);
    }
  };

  return (
    <div className="phone-ver-container">
      {verificationStatus !== 'approved' && !isLoading && (
        <div className="phone-verification">
          <h3>Verificación telefónica</h3>
          <p>
            Por favor, proporciona tu número de teléfono para verificar tu
            identidad y prevenir fraudes. Gracias.
          </p>
          <Form onChange={handleChange} onSubmit={handleSubmit}>
            {!otpInput && (
              <PhoneInput
                international
                defaultCountry="ES"
                countrySelectProps={{ unicodeFlags: true }}
                value={phoneNumber}
                onChange={setPhoneNumber}
                focusInputOnCountrySelection={true}
                error="Error"
              />
            )}
            {otpInput && (
              <>
                <Form.Label>Código de verificación</Form.Label>
                <Form.Control type="number" name="otpcode" />
              </>
            )}
            <br />
            <Button type="primary" disabled={isLoading}>
              {!otpInput ? 'Mandar SMS' : 'Verificar'}
            </Button>
          </Form>
        </div>
      )}
      {verificationStatus === 'approved' && !hasBeenError && !isLoading && (
        <div className="verification-success">
          <h3>¡Teléfono verificado con éxito!</h3>
          <p>
            Cuenta activada✅
            <br />
            Ya puedes realizar pedidos😉
          </p>
        </div>
      )}
      {isLoading && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
          <span>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            Cargando...
          </span>
        </div>
      )}
      <p style={{ color: 'red' }}>{error}</p>
    </div>
  );
}
