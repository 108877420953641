import React from 'react';
import Button from 'react-bootstrap/esm/Button';
import { Link } from 'react-router-dom';

const error404Image = require('../../assets/img/png/error404_2.png');

export default function Error404() {
  return (
    <>
      <div className="empty-cart">
        <h1>¡Oops!</h1>
        <br />
        <img
          className="cart-image"
          src={error404Image}
          alt="el pollo paulino encargo realizado"
        />
        <h4>No hemos encontrado lo que buscabas</h4>
        <br />
        <div className="show-menu-button">
          <Link to="/carta">
            <Button variant="warning" size="md">
              Volver a la carta
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
