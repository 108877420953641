//layouts
import LayoutBasic from '../layouts/LayoutBasic';
import LayoutOrder from '../layouts/LayoutOrder';
import LayoutAdmin from '../layouts/LayoutAdmin';

import AdminHome from '../pages/Admin/Home';
import AdminSignIn from '../pages/Admin/SignIn/SignIn';
import AdminUsers from '../pages/Admin/Users';
import AdminProducts from '../pages/Admin/Products';

import Home from '../pages/Home';
import Order from '../pages/Order';
import Checkout from '../components/Web/Orders/Checkout/Checkout';
// Other
import Error404 from '../components/Error404';
import Profile from '../components/Web/Orders/Profile';
import ListOrders from '../components/Admin/Orders/ListOrders/ListOrders';
import Claim from '../components/Web/Orders/Claim/Claim';
import ClaimDelivered from '../components/Web/Orders/Claim/ClaimDelivered';
import CheckOutCompleted from '../components/Web/Orders/Checkout/CheckOutCompleted';
import ListClaims from '../components/Admin/Claims/ListClaims';
import Activation from '../components/Web/Orders/Activation/Activation';
import SignUpSuccess from '../components/Web/Orders/SignIn/SignUpSuccess';
import ResetPassword from '../components/Web/Orders/ResetPassword';
import ResetPasswordSent from '../components/Web/Orders/ResetPassword/ResetPasswordSent';
import PhoneVerification from '../components/Web/Orders/SignIn/PhoneVerification';
import PrivacyAndUse from '../components/Web/PrivacyAndUse';
import Settings from '../components/Admin/Settings/Settings';
import EditUser from '../components/Web/Orders/EditUser/EditUser';
import OrderStream from '../components/Admin/OrderStream/OrderStream';

const routerAdmin = [
  {
    path: '/elpauporcjaris',
    component: AdminSignIn,
    layout: LayoutBasic,
  },
  {
    path: '/elpauporcjaris/dashboard',
    component: AdminHome,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/pedidos',
    component: ListOrders,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/pedidos/:userId',
    component: ListOrders,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/productos',
    component: AdminProducts,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/usuarios',
    component: AdminUsers,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/reclamaciones',
    component: ListClaims,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/ajustes',
    component: Settings,
    layout: LayoutAdmin,
  },
  {
    path: '/elpauporcjaris/order-stream',
    component: OrderStream,
    layout: LayoutAdmin,
  },
];

const routesClient = [
  {
    path: '/',
    component: Home,
    layout: LayoutBasic,
  },
  {
    path: '/carta',
    component: Order,
    layout: LayoutOrder,
  },
  {
    path: '/carrito',
    component: Checkout,
    layout: LayoutOrder,
  },
  {
    path: '/encargo-realizado',
    component: CheckOutCompleted,
    layout: LayoutOrder,
  },
  {
    path: '/perfil',
    component: Profile,
    layout: LayoutOrder,
  },
  {
    path: '/incidencia/:orderId',
    component: Claim,
    layout: LayoutOrder,
  },
  {
    path: '/incidencia-realizada',
    component: ClaimDelivered,
    layout: LayoutOrder,
  },
  {
    path: '/activation/:userId/:token/:activationId',
    component: Activation,
    layout: LayoutOrder,
  },
  {
    path: '/verificacion-sms/:userId',
    component: PhoneVerification,
    layout: LayoutOrder,
  },
  {
    path: '/registro-realizado',
    component: SignUpSuccess,
    layout: LayoutOrder,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    layout: LayoutOrder,
  },
  {
    path: '/reset-password/:userId/:token',
    component: ResetPassword,
    layout: LayoutOrder,
  },
  {
    path: '/password-changed-sent',
    component: ResetPasswordSent,
    layout: LayoutOrder,
  },
  {
    path: '/password-changed-correctly/:userId',
    component: ResetPasswordSent,
    layout: LayoutOrder,
  },
  {
    path: '/privacidad-y-condiciones-de-uso',
    component: PrivacyAndUse,
    layout: LayoutOrder,
  },
  {
    path: '/privacidad-y-condiciones-de-uso',
    component: PrivacyAndUse,
    layout: LayoutOrder,
  },
  {
    path: '/ajustes',
    component: EditUser,
    layout: LayoutOrder,
  },
];

const routes = [
  ...routesClient,
  ...routerAdmin,
  {
    path: '*',
    layout: LayoutOrder,
    component: Error404,
  },
];

export default routes;
