import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthProvider from './providers/AuthProvider';
import routes from './config/routes';
import './App.css';
import ConfigProvider from './providers/ConfigProvider';

function App() {
  return (
    <AuthProvider>
      <ConfigProvider>
        <Router>
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <route.layout>
                    <route.component />
                  </route.layout>
                }
              />
            ))}
          </Routes>
        </Router>
      </ConfigProvider>
    </AuthProvider>
  );
}

export default App;
