import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { askForPasswordChange, updateUserApi } from '../../../../api/user';

import './ResetPassword.scss';

export default function ResetPassword() {
  const { userId, token } = useParams();
  const [inputs, setInputs] = useState('');

  const navigate = useNavigate();
  useEffect(() => {}, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputs.email);
    if (!userId && !token) {
      const emailRegex =
        // eslint-disable-next-line no-useless-escape
        /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      if (emailRegex.test(inputs.email)) {
        askForPasswordChange(inputs.email).then((res) => {
          console.log(res);
          navigate('/password-changed-sent');
        });
      } else {
        //Show error
      }
    } else if (userId && token) {
      //Set new password
      if (inputs.newPassword !== inputs.newPasswordRepeat) {
        //handle error
      } else {
        console.log('Setting new password');
        updateUserApi(token, { password: inputs.newPassword }, userId)
          .then((res) => {
            navigate(`/password-changed-correctly/${userId}`);
          })
          .catch((err) => {
            //Set error
          });
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="reset-password-container">
        {userId && token && (
          <>
            <h4>Restablecer contraseña</h4>
          </>
        )}
        {!userId && !token && (
          <>
            <h4>He olvidado mi contraseña</h4>
            <br />
            <h6>Te enviaremos un email para restablecer la contraseña</h6>
          </>
        )}
        <Form onChange={handleChange}>
          {!userId && !token && (
            <Row>
              <Col md={6}>
                <Form.Label>Correo electrónico</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Introduce tu email"
                  name="email"
                />
              </Col>
            </Row>
          )}

          {userId && token && (
            <div>
              <Row>
                <Col>
                  <Form.Label>Nueva contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Introduce la nueva contraseña"
                    name="newPassword"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Repite la contraseña</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Repite de nuevo la contraseña"
                    name="newPasswordRepeat"
                  />
                </Col>
              </Row>
            </div>
          )}
          <br />
          <Row>
            <Col>
              <Button type="primary" onClick={handleSubmit}>
                {!userId && !token && 'Solicitar restablecimiento'}
                {userId && token && 'Cambiar contraseña'}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}
