import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { addOrderApi } from '../../../../../api/order';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import { getUserInfo } from '../../../../../api/user';
import { dropCartApi } from '../../../../../api/cart';
import { Spinner } from 'react-bootstrap';

function getDayOfWeek(dateString) {
  const date = new Date(dateString.split('/').reverse().join('/'));
  return date.getDay();
}

function getStoreHours(array, day) {
  const morningShift = array.filter(
    (obj) => obj.dayCode === day && obj.shift === 'morning'
  );
  const eveningShift = array.filter(
    (obj) => obj.dayCode === day && obj.shift === 'evening'
  );

  if (eveningShift.length > 0) {
    return [
      parseInt(morningShift[0].open.split(':')[0]),
      parseInt(eveningShift[0].close.split(':')[0]),
    ];
  } else {
    return [
      parseInt(morningShift[0].open.split(':')[0]),
      parseInt(morningShift[0].close.split(':')[0]),
    ];
  }
}

export default function CheckOutForm(props) {
  const {
    times,
    schedule,
    products,
    sendingOrder,
    setSendingOrder,
    acceptingOrders,
  } = props;
  const now = moment(moment()).format('DD/MM/YYYY').split('/');
  const [date, setDate] = useState(moment(moment()).format('DD/MM/YYYY'));
  const [time, setTime] = useState(null);
  const [customTimes, setCustomTimes] = useState([]);
  const [sameDay, setSameDay] = useState(true);
  const [indications, setIndications] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    let t_customTimes = [];
    if (times && schedule) {
      const storeHoursArr = getStoreHours(
        schedule.times,
        getDayOfWeek(date).toString()
      );

      if (sameDay) {
        times.forEach((element) => {
          const orderTime = element.split(':');
          const orderH = orderTime[0];
          const orderM = orderTime[1];

          if (
            checkThirtyMinutes(orderH, orderM) &&
            sameDay &&
            orderH <= storeHoursArr[1]
          ) {
            t_customTimes.push(element);
          }
        });
      } else {
        times.forEach((element) => {
          const orderTime = element.split(':');
          const orderH = orderTime[0];

          if (orderH <= storeHoursArr[1]) {
            t_customTimes.push(element);
          }
        });
      }
    }

    setCustomTimes(t_customTimes);
  }, [sameDay, date, times, schedule]);

  useEffect(() => {
    if (user && !isLoading) {
      if (!user.phoneNumber) {
        getUserInfo()
          .then((response) => {
            setPhoneNumber(response.phoneNumber);
          })
          .catch((e) => {
            setError('Ha ocurrido un error al solicitar los datos de usuario.');
          });
      } else {
        console.log('Setting phone number..' + user.phoneNumber);
        setPhoneNumber(user.phoneNumber);
      }
    }
  }, [user, isLoading]);

  const checkThirtyMinutes = (orderH, orderM) => {
    let orderMinutes = parseInt(orderH) * 60 + parseInt(orderM);
    const nowMinutes = new Date().getHours() * 60 + new Date().getMinutes();
    return orderMinutes - nowMinutes >= 30 ? true : false;
  };

  const clearProducts = (products) => {
    let t_products = products;
    let clearedProducts = [];
    t_products.forEach((product) => {
      clearedProducts.push({
        categoryName: product.categoryName,
        name: product.name,
        _id: product._id,
        variant: product.variant,
      });
    });
    return clearedProducts;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSendingOrder(true);

    try {
      if (time !== null || date !== null) {
        const clearedProducts = clearProducts(products);
        const dateSplit = date.split('/');
        const timeSplit = time.split(':');
        const orderDate = new Date(
          dateSplit[2],
          dateSplit[1] - 1,
          dateSplit[0],
          timeSplit[0],
          timeSplit[1],
          '00'
        );
        const order = {
          phoneNumber: phoneNumber,
          orderDay: orderDate.getUTCDate(),
          orderMonth: orderDate.getUTCMonth(),
          orderYear: orderDate.getUTCFullYear(),
          time: time,
          orderDate: orderDate,
          items: clearedProducts,
          indications: indications,
        };
        setError('');
        if (acceptingOrders) {
          addOrderApi(order)
            .then((response) => {
              dropCartApi()
                .then((response) => {
                  navigate('/encargo-realizado');
                })
                .catch((err) => {
                  console.log(err);
                  setError(
                    'Ha ocurrido un error al procesar su pedido. Inténtelo de nuevo por favor'
                  );
                  setSendingOrder(false);
                });
            })
            .catch((err) => {
              setError(
                'Ha ocurrido un error al procesar su pedido. Inténtelo de nuevo por favor'
              );
              setSendingOrder(false);
            });
        } else {
          setError(
            'Lo sentimos pero actualmente no estamos aceptando pedidos web.'
          );
        }
      } else {
        if (phoneNumber < 9) {
          setError('Introduce un número de teléfono válido');
          setSendingOrder(false);
        } else {
          setError('Debes elegir una fecha y una hora para recoger el pedido');
          setSendingOrder(false);
        }
      }
    } catch (e) {
      console.error(e);
      setError('Debes elegir una fecha y una hora para recoger el pedido');
      setSendingOrder(false);
    }
  };

  const checkDate = (date) => {
    const momentOrder = moment(date).format('DD/MM/YYYY').split('/');

    const orderDay = momentOrder[0];
    const orderMonth = momentOrder[1];
    const orderYear = momentOrder[2];

    const day = now[0];
    const month = now[1];
    const year = now[2];

    if (day === orderDay && month === orderMonth && year === orderYear) {
      setSameDay(true);
      setDate(`${orderDay}/${orderMonth}/${orderYear}`);
    } else if (moment.utc().isAfter(date)) {
      setSameDay(false);
      alert('Introduce una fecha válida (de hoy en adelante)');
      document.getElementById('date-picker-order').value = moment(moment())
        .format('DD/MM/YYYY')
        .split('/');
      return;
    } else {
      setSameDay(false);
      setDate(`${orderDay}/${orderMonth}/${orderYear}`);
    }
  };

  const checkTime = (time) => {
    const orderTime = time.split(':');
    const orderH = orderTime[0];
    const orderM = orderTime[1];
    const storeHoursArr = getStoreHours(
      schedule.times,
      getDayOfWeek(date).toString()
    );
    if (!checkThirtyMinutes(orderH, orderM) && sameDay) {
      alert('Se debe pedir con al menos media hora de antelación');
      //setTime(moment().add(30, 'minutes').hour());
    } else if (orderH >= storeHoursArr[1] || orderH < storeHoursArr[0]) {
      setTime(moment().add(30, 'minutes').hour());
    } else {
      setError('');
      setTime(time);
    }
  };

  const today = new Date().toISOString().split('T')[0];
  return (
    <>
      {!sendingOrder && (
        <Form style={{ marginBottom: '50px' }}>
          <Form.Group className="mb-3" style={{ display: 'block' }}>
            <div className="form-time">
              <div style={{ minWidth: '30%' }}>
                <Form.Label>Fecha de recogida</Form.Label>
                <InputGroup>
                  <InputGroup.Text id="basic-addon1">
                    <FontAwesomeIcon icon={faCalendar} />
                  </InputGroup.Text>
                  <Form.Control
                    defaultValue={today}
                    id="date-picker-order"
                    type="date"
                    min={today}
                    style={{ paddingRight: '6px' }}
                    onChange={(e) => {
                      checkDate(e.target.valueAsDate);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
          </Form.Group>
          <Form.Group>
            <div className="times-parent-box">
              <Form.Label style={{ alignSelf: 'flex-start' }}>
                Hora de recogida
              </Form.Label>
              <div className="times-layout">
                {sameDay &&
                  customTimes.map((e) => {
                    return (
                      <TimeButton
                        key={e}
                        time={e}
                        handleClick={() => checkTime(e)}
                        isSelected={e === time}
                      />
                    );
                  })}
                {!sameDay &&
                  customTimes.map((e) => {
                    return (
                      <TimeButton
                        key={e}
                        time={e}
                        handleClick={() => checkTime(e)}
                        isSelected={e === time}
                      />
                    );
                  })}
                {!(customTimes.length > 0) && sameDay && (
                  <p style={{ fontWeight: 'bold', color: 'red' }}>
                    No hay horarios disponibles para hoy.
                    <br />
                    Por favor, selecciona otro día
                  </p>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Número de teléfono</Form.Label>
            <Form.Control
              style={{ width: 'auto' }}
              type="text"
              maxLength={9}
              name="phone"
              disabled
              value={phoneNumber || ''}
              onChange={(e) => {
                /*             const re = /^[0-9\b]+$/;

            if (
              (e.target.value === '' || re.test(e.target.value)) &&
              e.target.value.length <= 9
            ) {
              console.log(e.target.value);
              setPhoneNumber(e.target.value);
            }
 */
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Comentarios acerca del pedido</Form.Label>
            <Form.Control
              style={{ minHeight: '100px' }}
              autoComplete="none"
              as="textarea"
              placeholder="Coméntanos que podemos hacer para que todo sea perfecto para ti (alérgenos, salsas...)"
              name="comments"
              maxLength={300}
              onChange={(e) => {
                if (e.target.value.length <= 300) {
                  setIndications(e.target.value);
                  setError('');
                } else {
                  setError(
                    'El tamaño máximo de las indicaciones es de 300 caracteres.'
                  );
                }
              }}
            />
          </Form.Group>
          <Form.Group>
            <p style={{ color: 'red' }}>{error}</p>
            <Button
              className="submit-button"
              variant="warning"
              disabled={sendingOrder || !acceptingOrders}
              type="submit"
              style={{ width: '30%', marginLeft: '70%' }}
              onClick={(e) => {
                setSendingOrder(true);
                handleSubmit(e);
              }}
            >
              Realizar pedido
            </Button>
          </Form.Group>
        </Form>
      )}

      {sendingOrder && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
          <span>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            Enviando el pedido
          </span>
        </div>
      )}
    </>
  );
}

function TimeButton(props) {
  const { isSelected, handleClick, time } = props;
  return (
    <div
      className="time-button"
      onClick={handleClick}
      style={
        isSelected
          ? {
              backgroundColor: '#ffc107',
              color: 'white',
              borderColor: 'white',
            }
          : {}
      }
    >
      {time}
    </div>
  );
}
