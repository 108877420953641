import React from 'react';

import './PrivacyAndUse.scss';

export default function PrivacyAndUse() {
  return (
    <div className="privacy-container">
      <a href="#condicionesUso">Ver condiciones de uso</a>
      <h2>Política de privacidad de HIYOKO S.L.</h2>
      <p>Razón Social: Hiyoko Sl.</p>
      <p>CIF: B71289995</p>
      <p>
        Domicilio Social: Calle Paulino Caballero, 18 - BJ, Pamplona/iruña,
        31003 , Navarra
      </p>
      <p>
        En HIYOKO S.L., valoramos la privacidad de nuestros usuarios y estamos
        comprometidos con la protección de sus datos personales. Por lo tanto,
        hemos desarrollado esta política de privacidad para informar a nuestros
        usuarios sobre cómo recopilamos, usamos, almacenamos y protegemos sus
        datos personales.
      </p>
      <h5>Recopilación de datos:</h5>
      <p>
        Recopilamos información personal de nuestros usuarios cuando se
        registran en nuestro sitio web, realizan un pedido o se suscriben a
        nuestro boletín informativo. La información que recopilamos incluye
        nombre, apellido, número de teléfono, dirección postal, correo
        electrónico y cualquier otra información que proporcione
        voluntariamente.
      </p>
      <h5>Uso de datos:</h5>
      <p>
        Utilizamos la información personal de nuestros usuarios para procesar
        sus pedidos, enviarles actualizaciones sobre sus pedidos y mejorar su
        experiencia en nuestro sitio web. También podemos usar su información
        para enviarle ofertas y promociones especiales.
      </p>
      <h5>Almacenamiento de datos:</h5>
      <p>
        Almacenamos la información personal de nuestros usuarios de manera
        segura y por un período limitado de tiempo necesario para cumplir con
        nuestras obligaciones legales y comerciales.
      </p>
      <h5>Compartición de datos:</h5>
      <p>
        No compartimos ni vendemos la información personal de nuestros usuarios
        a terceros a menos que se les requiera por ley o sea necesario para el
        procesamiento de sus pedidos.
      </p>
      <h5>Derechos de los usuarios:</h5>
      <p>
        Nuestros usuarios tienen derecho a acceder, corregir y eliminar sus
        datos personales en cualquier momento. También pueden solicitar la
        limitación del tratamiento de sus datos o oponerse al tratamiento. Si
        desea ejercer alguno de estos derechos, por favor contáctenos a través
        de nuestro correo electrónico{' '}
        <a href="mailto:info@elpollopaulino.com">info@elpollopaulino.com</a>.
      </p>
      <h5>Medidas de seguridad:</h5>
      <p>
        Tomamos medidas de seguridad razonables para proteger la información
        personal de nuestros usuarios contra pérdida, robo, uso indebido y
        acceso no autorizado. Sin embargo, no podemos garantizar la seguridad
        absoluta de la información enviada a través de Internet.
      </p>
      <h5>Cambios en la política de privacidad:</h5>
      <p>
        Nos reservamos el derecho de modificar esta política de privacidad en
        cualquier momento. Cualquier cambio será publicado en esta página y se
        notificará a los usuarios a través de un aviso en nuestro sitio web o
        mediante un correo electrónico. Por favor revise esta política de
        privacidad periódicamente para estar informado de cualquier cambio.
      </p>
      <h5>Contacto: </h5>
      <p>
        Si tiene alguna pregunta o inquietud sobre esta política de privacidad o
        sobre cómo manejamos sus datos personales, por favor no dude en
        contactarnos a través de nuestro correo electrónico
        <a href="mailto:info@elpollopaulino.com">info@elpollopaulino.com</a>.
      </p>
      <p>
        Esta política de privacidad se rige por las leyes de protección de datos
        de España y la Unión Europea, incluyendo el Reglamento General de
        Protección de Datos (RGPD).
      </p>
      <br />
      <h2 id="condicionesUso">Condiciones de uso de HIYOKO S.L.</h2>
      <p>
        Bienvenido a nuestro sitio web de pedidos en línea. Al acceder y
        utilizar este sitio, acepta los siguientes términos y condiciones de
        uso. Si no está de acuerdo con estas condiciones, por favor, no utilice
        este sitio.
      </p>
      <h5>Uso del sitio:</h5>
      <p>
        Este sitio web está diseñado para su uso personal y no comercial. No
        puede modificar, copiar, distribuir, transmitir, mostrar, ejecutar,
        reproducir, publicar, conceder licencias, crear trabajos derivados o
        transferir ningún material contenido en este sitio sin nuestro previo
        consentimiento por escrito.
      </p>
      <h5>Derechos de propiedad:</h5>
      <p>
        Todos los derechos de propiedad intelectual, incluyendo derechos de
        autor y marcas registradas, en este sitio web y sus contenidos son
        propiedad de HIYOKO S.L. o de sus proveedores de contenido y están
        protegidos por las leyes de propiedad intelectual aplicables.
      </p>
      <h5>Responsabilidad del usuario:</h5>
      <p>
        Es responsabilidad del usuario utilizar este sitio de manera responsable
        y cumplir con todas las leyes aplicables, incluyendo las leyes de
        propiedad intelectual. El usuario es responsable de cualquier daño o
        pérdida resultante de su incumplimiento de estas condiciones de uso.
        Este sitio web y los servicios que ofrece están disponibles solo para
        personas mayores de 18 años. Al utilizar este sitio web y proporcionar
        información personal, representa y garantiza que tiene al menos 18 años
        de edad. HIYOKO S.L. no recopila intencionalmente información de
        personas menores de 18 años y no es responsable por cualquier uso ilegal
        o no autorizado por parte de menores. Si se descubre que se ha
        recopilado información de un menor sin su consentimiento parental, se
        eliminará de manera inmediata.
      </p>
      <h5>Precisión de la información:</h5>
      <p>
        Nos esforzamos por proporcionar información precisa en este sitio web.
        Sin embargo, no garantizamos la precisión, integridad o actualidad de la
        información contenida en este sitio.
      </p>
      <h5>Modificaciones del sitio:</h5>
      <p>
        Nos reservamos el derecho de modificar, suspender o descontinuar
        cualquier aspecto de este sitio en cualquier momento, sin previo aviso.
      </p>
      <h5>Enlaces a otros sitios web:</h5>
      <p>
        Este sitio puede contener enlaces a otros sitios web que no son
        propiedad o están controlados por HIYOKO S.L. No somos responsables del
        contenido o la privacidad de estos sitios. Al acceder a cualquier sitio
        externo a través de un enlace en este sitio, lo hace bajo su propio
        riesgo.
      </p>
      <h5>Disponibilidad del producto:</h5>
      <p>
        Nos reservamos el derecho de modificar o descontinuar la disponibilidad
        de cualquier producto o servicio en cualquier momento sin previo aviso.
      </p>
      <h5>Garantía:</h5>
      <p>
        EXCEPTO EN LA MEDIDA EN QUE SEA PROHIBIDO POR LA LEY, ESTE SITIO WEB Y
        TODOS LOS PRODUCTOS Y SERVICIOS SE PROPORCIONAN "TAL CUAL" SIN GARANTÍA
        DE NINGÚN TIPO. NO GARANTIZAMOS LA PRECISIÓN, INTEGRIDAD O ACTUALIDAD DE
        LA INFORMACIÓN CONTENIDA EN ESTE SITIO, NI GARANTIZAMOS QUE EL USO DE
        ESTE SITIO SERÁ ININTERRUMPIDO O LIBRE DE ERRORES.
      </p>
      <h5>Indemnización:</h5>
      <p>
        Acepta indemnizar, defender y eximir de responsabilidad a HIYOKO S.L. y
        a sus afiliados, directivos, empleados, agentes y proveedores, de
        cualquier reclamo, demanda, responsabilidad, daño, pérdida, costo o
        gasto, incluyendo honorarios de abogados, que surjan de su uso de este
        sitio web o cualquier incumplimiento de estas condiciones de uso.
      </p>
      <h5>Jurisdicción:</h5>
      <p>
        Estas condiciones de uso se rigen e interpretan de acuerdo con las leyes
        de España y la Unión Europea. Cualquier acción legal relacionada con el
        uso de este sitio web será presentada en los tribunales competentes de
        España.
      </p>
      <p>Fecha de efectividad: 8 de febrero de 2023.</p>
    </div>
  );
}
