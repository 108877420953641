import React from 'react';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        position: 'absolute',
        fontSize: '12px',
        backgroundColor: 'transparent',
        bottom: '0',
        height: '30px',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <span>
        <a href="/privacidad-y-condiciones-de-uso">
          Política de privacidad y condiciones de uso
        </a>
      </span>
      <br />
      <span>© {currentYear} el Pollo Paulino - </span>
      <span>
        Diseño web por{' '}
        <a href="https://www.pablooses.com" target="_blank" rel="noreferrer">
          Pablo Osés
        </a>
      </span>
    </footer>
  );
}
