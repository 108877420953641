import React from 'react';

import { default as BootsModal } from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/esm/Button';

export default function Modal(props) {
  const { handleClose, show, body, footer, title, wide } = props;

  return (
    <>
      <BootsModal
        show={show}
        onHide={handleClose}
        style={wide ? { width: '100%' } : {}}
      >
        <BootsModal.Header closeButton>
          <BootsModal.Title>{title}</BootsModal.Title>
        </BootsModal.Header>
        <BootsModal.Body>{body}</BootsModal.Body>
        {footer && (
          <BootsModal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Save Changes
            </Button>
          </BootsModal.Footer>
        )}
      </BootsModal>
    </>
  );
}
