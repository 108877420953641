import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  FloatingLabel,
  InputGroup,
} from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

import { addProductApi, uploadProductImage } from '../../../../api/product';

export default function AddProduct(props) {
  const { categoryId, setRefreshProducts, closeModal } = props;

  const [image, setImage] = useState(null);
  const [product, setProduct] = useState({
    name: '',
    description: '',
    variant: [],
  });

  let variants = product.variant;
  const handleChange = (e) => {
    e.preventDefault();
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
    if (variants.length > 0) {
      setProduct({ ...product, price: product.variant[0].price });
    }
  };

  const handleVariantChange = (e, index) => {
    e.preventDefault();
    variants[index][e.target.name] = e.target.value;
    setProduct({ ...product, variant: variants });
  };

  const handleCheckChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.checked,
    });
  };

  const addProduct = (e) => {
    e.preventDefault();
    if (product.variant.length > 0) {
      setProduct({ ...product, price: product.variant[0].price });
    }
    addProductApi(product, categoryId).then((response) => {
      response.result.products.forEach((e) => {
        if (
          e.name === product.name &&
          e.price === parseFloat(product.price) &&
          e.description === product.description
        ) {
          uploadProductImage(categoryId, e._id, image).then((response) => {
            setRefreshProducts(true);
            closeModal();
          });
        }
      });
    });
  };

  return (
    <Form onSubmit={addProduct} onChange={handleChange}>
      <Row style={{ justifyContent: 'center' }}>
        <UploadImage setImage={setImage} image={image} />
      </Row>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Nombre producto"
            className="mb-3"
          >
            <Form.Control type="text" name="name" />
          </FloatingLabel>
        </Col>
        <Col>
          <InputGroup className="mb-3">
            <FloatingLabel
              controlId="floatingInput"
              label="Precio"
              className="mb-3"
            >
              <Form.Control
                name="price"
                type="number"
                step="0.01"
                placeholder="Nombre producto"
              />
            </FloatingLabel>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FloatingLabel
            controlId="floatingInput"
            label="Descripción"
            className="mb-3"
          >
            <Form.Control
              as="textArea"
              placeholder="Description"
              name="description"
              style={{ minHeight: '100px' }}
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="add-variant-button"
            variant="outline-primary"
            onClick={() => {
              variants.push({ name: '', price: 0 });
              setProduct({ ...product, variant: variants });
            }}
          >
            + Añadir variante
          </Button>
          {variants.length > 0 &&
            product.variant.map((variant, index) => {
              return (
                <div className="food-variation-field">
                  <Row>
                    <Col xs={6}>
                      <Form.Control
                        type="text"
                        name="type"
                        placeholder="Nombre variante"
                        value={variant.type}
                        onChange={(e) => {
                          e.preventDefault();
                          handleVariantChange(e, index);
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <InputGroup>
                        <Form.Control
                          type="number"
                          name="price"
                          value={variant.price}
                          placeholder="Precio variante"
                          onChange={(e) => {
                            e.preventDefault();
                            handleVariantChange(e, index);
                          }}
                        />
                        <InputGroup.Text>€</InputGroup.Text>
                      </InputGroup>
                    </Col>
                    <Col xs={2}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          setProduct({
                            ...product,
                            variant: variants,
                          });
                        }}
                      >
                        X
                      </Button>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </Col>
      </Row>
      <Row>
        <div style={{ margin: '10px 0 10px 0' }}>
          <Form.Check
            id="checkbox"
            type="checkbox"
            label="Sólo por teléfono"
            name="phoneOnly"
            size="lg"
            onChange={handleCheckChange}
          />
        </div>
      </Row>
      <Row>
        <Col>
          <p style={{ fontSize: 12, color: 'red', textAlign: 'center' }}>
            Por defecto el producto estará inactivo.
            <br />
            Actívelo y desactívelo a su gusto después de su creación editando el
            producto.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button type="submit" style={{ width: '100%' }}>
            Añadir producto
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function UploadImage(props) {
  const { image, setImage } = props;
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (image) {
      if (image.preview) {
        setAvatarUrl(image.preview);
      } else {
        setAvatarUrl(image);
      }
    } else {
      setAvatarUrl(null);
    }
  }, [image]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setImage({ file, preview: URL.createObjectURL(file) });
    },
    [setImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <div
      style={{
        width: '200px',
        height: '200px',
        margin: '20px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        border: '2px dashed #cecece',
        borderRadius: '50%',
        overflow: 'hidden',
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <img style={{ width: '100%' }} src={image} />
      ) : (
        <img
          style={{ width: '180px', height: '180px', borderRadius: '50%' }}
          src={avatarUrl ? avatarUrl : ''}
          alt="imagen de producto"
        />
      )}
    </div>
  );
}
