import { getAccessTokenApi, getAdminAccessTokenApi } from './auth';
import { BASE_PATH, API_VERSION } from './config';

export async function addClaimApi(payload) {
  const token = await getAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/add-claim/`;

  const params = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      //Remove cart
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      //Remove cart
      return response;
    });
}

export async function getClaimsFilteredApi(filters) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/get-claims/`;
  const params = {
    method: 'POST',
    body: JSON.stringify(filters),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function updateClaimSolutionApi(solution) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/update-claim-solution/`;
  const params = {
    method: 'PUT',
    body: JSON.stringify(solution),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
