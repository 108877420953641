import React, { useState, useEffect, createContext } from 'react';
import { getConfigApi } from '../api/settings';
export const ConfigContext = createContext();

export default function ConfigProvider(props) {
  //Children is full web app
  const { children } = props;
  const [config, setConfig] = useState({
    acceptingOrders: false,
    isLoading: true,
  });

  useEffect(() => {
    getConfigApi()
      .then((response) => {
        setConfig({
          acceptingOrders: response.config.acceptingOrders,
          isLoading: false,
        });
      })
      .catch((e) => {
        setConfig({
          acceptingOrders: e,
          isLoading: false,
        });
      });
  }, []);

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
}
