/* import React, { useState, useEffect, useRef } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {
  getOrdersFilteredApi,
  updateOrderStatusApi,
} from '../../../../api/order';
import { useParams, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

export default function ListOrders() {
  const { userId } = useParams();
  const [orders, setOrders] = useState([]);
  const [reloadOrders, setReloadOrders] = useState(false);
  const [filters, setFilters] = useState({
    phoneNumber: '',
    customerName: '',
    orderStatus: 'PENDIENTE',
    orderDayFrom: '',
    orderMonthFrom: '',
    orderYearFrom: '',
    userId: '',
  });
  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();

  const sortItemsByCategory = (orders) => {
    orders.forEach((order) => {
      let newItems = {};
      order.items.forEach((item) => {
        if (!newItems[`${item.categoryName}`]) {
          newItems[`${item.categoryName}`] = [];
          newItems[`${item.categoryName}`].push(item);
        } else {
          newItems[`${item.categoryName}`].push(item);
        }
      });

      const sortedCategories = Object.keys(newItems).sort();

      const sortedNewItems = {};
      sortedCategories.forEach((category) => {
        sortedNewItems[category] = newItems[category];
      });

      order.items = sortedNewItems;
    });

    return orders;
  };

  useEffect(() => {
    if (userId) {
      setFilters({ ...filters, userId: userId });
      getOrdersFilteredApi(filters)
        .then((response) => {
          const sortedOrders = sortItemsByCategory(response);
          setOrders(sortedOrders);
          setReloadOrders(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  useEffect(() => {
    getOrdersFilteredApi(filters)
      .then((response) => {
        const sortedOrders = sortItemsByCategory(response);
        setOrders(sortedOrders);
        setReloadOrders(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadOrders]);

  const handleDateChange = (e) => {
    e.preventDefault();
    var filterDate = new Date(e.target.value);
    if (e.target.name === 'dateFrom')
      setFilters({
        ...filters,
        orderDayFrom: filterDate.getUTCDate(),
        orderMonthFrom: filterDate.getUTCMonth(),
        orderYearFrom: filterDate.getUTCFullYear(),
        dateFrom: filterDate,
      });
    if (e.target.name === 'dateTo')
      setFilters({
        ...filters,
        orderDayTo: filterDate.getUTCDate(),
        orderMonthTo: filterDate.getUTCMonth(),
        orderYearTo: filterDate.getUTCFullYear(),
        dateTo: filterDate,
      });
  };

  const downloadCSV = async () => {
    // Prepare the data to be exported as CSV
    const csvArray = orders.map((order) => {
      return {
        Id_Pedido: order._id,
        Fecha_pedido: new Date(order.orderDate).toLocaleDateString(),
        Total_pedido: `${order.total}€`,
        Cliente: order.customerName,
        Numero_telefono: order.phoneNumber,
        Estado_del_pedido: order.orderStatus,
      };
    });
    console.log(csvArray);
    setCsvData(csvArray);
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters({
      phoneNumber: '',
      customerName: '',
      orderStatus: '',
      orderDayFrom: '',
      orderMonthFrom: '',
      orderYearFrom: '',
      dateFrom: null,
      dateTo: null,
    });
    getOrdersFilteredApi({})
      .then((response) => {
        const sortedOrders = sortItemsByCategory(response);
        setOrders(sortedOrders);
        setReloadOrders(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyFilters = async (e) => {
    e.preventDefault();
    try {
      new Date(filters.dateFrom);
      new Date(filters.dateTo);
    } catch (e) {
      console.log(e);
      setFilters({ ...filters, dateTo: null, dateFrom: null });
    }

    if (filters.dateTo === null) {
      setFilters({ ...filters, dateTo: null, dateFrom: null });
    }

    await getOrdersFilteredApi(filters)
      .then((response) => {
        const sortedOrders = sortItemsByCategory(response);
        setOrders(sortedOrders);
        setReloadOrders(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1>Pedidos</h1>
      <Link to="/elpauporcjaris/order-stream">
        <Button>Pedidos en tiempo real (beta)</Button>
      </Link>
      <div className="filter-bar">
        <Form onSubmit={applyFilters}>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Fecha de entrega (Desde)</Form.Label>
                <Form.Control
                  name="dateFrom"
                  type="date"
                  onChange={handleDateChange}
                />
              </Col>
              <Col>
                <Form.Label>
                  Fecha de entrega (Hasta){' '}
                  <span style={{ fontSize: '7px', fontWeight: 'bold' }}>
                    NO INCLUÍDO
                  </span>
                </Form.Label>
                <Form.Control
                  name="dateTo"
                  type="date"
                  onChange={handleDateChange}
                />
              </Col>
            </Row>

            <Col>
              <Form.Label>Estado del pedido</Form.Label>
              <Form.Select
                name="orderStatus"
                aria-label="estado-pedido"
                value={filters.orderStatus}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option value="">CUALQUIERA</option>
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="ENTREGADO">ENTREGADO</option>
                <option value="CANCELADO">CANCELADO</option>
                <option value="INCIDENCIA">INCIDENCIA</option>
                <option value="SOLUCIONADO">SOLUCIONADO</option>
              </Form.Select>
            </Col>

            <Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  maxLength={9}
                  value={filters.phoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 9) {
                      setFilters({
                        ...filters,
                        [e.target.name]: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="false"
                  name="customerName"
                  value={filters.customerName}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Col>
            </Col>

            <br />
            <Button type="submit" variant="success">
              Aplicar filtros
            </Button>
            <Button variant="dark" onClick={resetFilters}>
              Borrar filtros
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                downloadCSV();
              }}
            >
              <CSVLink
                data={csvData}
                filename={`pedidos_desde_${new Date(
                  filters.dateFrom
                ).toLocaleDateString()}_hasta_${new Date(
                  filters.dateTo
                ).toLocaleDateString()}.csv`}
                className="btn btn-primary"
                target="_blank"
              >
                Descargar CSV
              </CSVLink>
            </Button>

            <br />
            <br />
          </Form.Group>
        </Form>
      </div>
      <Table striped bordered hover size="md" responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Productos</th>
            <th>Info. cliente</th>
            <th>Estado</th>
            <th>Creado el</th>
            <th>Fecha de entrega</th>
            <th>Comentarios</th>
            <th>Total</th>
            <th>Entregar</th>
          </tr>
        </thead>
        <tbody>
          {orders.map &&
            orders.map((order, index) => (
              <tr key={order.id}>
                <td>{index + 1}</td>
                <td>
                  <div>
                    {order.items &&
                      Object.keys(order.items).map((category) => {
                        return (
                          <div>
                            <h6>{category}</h6>
                            {order.items[`${category}`].length > 0 &&
                              order.items[`${category}`].map((item) => {
                                return (
                                  <div key={`${order._id}${item._id}`}>
                                    {item.name} x {item.quantity}
                                  </div>
                                );
                              })}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </td>
                <td>
                  {order.customerName}
                  <br />
                  {order.phoneNumber}
                </td>
                <td>{order.orderStatus}</td>
                <td>
                  {new Date(order.createdAt).toLocaleDateString()}{' '}
                  {new Date(order.createdAt).toLocaleTimeString()}{' '}
                </td>
                <td>
                  {new Date(order.orderDate).toLocaleDateString()}{' '}
                  {new Date(order.orderDate).toLocaleTimeString()}{' '}
                </td>
                <td>{order.indications}</td>
                <td>{order.total}€</td>

                <td>
                  <BootstrapSwitchButton
                    data-onlabel="SÍ"
                    data-offlabel="NO"
                    checked={order.orderStatus === 'ENTREGADO' ? true : false}
                    disabled={order.orderStatus === 'ENTREGADO' ? true : false}
                    onChange={(checked) => {
                      order.orderStatus = checked ? 'ENTREGADO' : 'PENDIENTE';
                      updateOrderStatusApi(order._id, order.orderStatus).then(
                        (response) => {
                          setReloadOrders(true);
                        }
                      );
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
} */

import React, { useState, useEffect, useCallback } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import {
  getOrdersFilteredApi,
  updateOrderStatusApi,
} from '../../../../api/order';
import { useParams, Link } from 'react-router-dom';
import { CSVLink } from 'react-csv';

export default function ListOrders() {
  const { userId } = useParams();
  const [orders, setOrders] = useState([]);
  const [reloadOrders, setReloadOrders] = useState(false);
  const [filters, setFilters] = useState({
    phoneNumber: '',
    customerName: '',
    orderStatus: 'PENDIENTE',
    orderDayFrom: '',
    orderMonthFrom: '',
    orderYearFrom: '',
    userId: '',
  });
  const [csvData, setCsvData] = useState([]);

  const sortItemsByCategory = useCallback((orders) => {
    return orders.map((order) => {
      const newItems = order.items.reduce((acc, item) => {
        const { categoryName } = item;
        if (!acc[categoryName]) {
          acc[categoryName] = [];
        }
        acc[categoryName].push(item);
        return acc;
      }, {});

      const sortedCategories = Object.keys(newItems).sort();
      const sortedNewItems = sortedCategories.reduce((acc, category) => {
        acc[category] = newItems[category];
        return acc;
      }, {});

      return { ...order, items: sortedNewItems };
    });
  }, []);

  const fetchOrders = useCallback(async () => {
    try {
      const response = await getOrdersFilteredApi(filters);
      const sortedOrders = sortItemsByCategory(response);
      setOrders(sortedOrders);
      setReloadOrders(false);
    } catch (err) {
      console.log(err);
    }
  }, [filters, sortItemsByCategory]);

  useEffect(() => {
    if (userId) {
      setFilters((prevFilters) => ({ ...prevFilters, userId }));
    }
    fetchOrders();
  }, [userId, fetchOrders]);

  useEffect(() => {
    if (reloadOrders) {
      fetchOrders();
    }
  }, [reloadOrders, fetchOrders]);

  const handleDateChange = (e) => {
    e.preventDefault();
    var filterDate = new Date(e.target.value);
    if (e.target.name === 'dateFrom')
      setFilters({
        ...filters,
        orderDayFrom: filterDate.getUTCDate(),
        orderMonthFrom: filterDate.getUTCMonth(),
        orderYearFrom: filterDate.getUTCFullYear(),
        dateFrom: filterDate,
      });
    if (e.target.name === 'dateTo')
      setFilters({
        ...filters,
        orderDayTo: filterDate.getUTCDate(),
        orderMonthTo: filterDate.getUTCMonth(),
        orderYearTo: filterDate.getUTCFullYear(),
        dateTo: filterDate,
      });
  };

  const downloadCSV = async () => {
    // Prepare the data to be exported as CSV
    const csvArray = orders.map((order) => {
      return {
        Id_Pedido: order._id,
        Fecha_pedido: new Date(order.orderDate).toLocaleDateString(),
        Total_pedido: `${order.total}€`,
        Cliente: order.customerName,
        Numero_telefono: order.phoneNumber,
        Estado_del_pedido: order.orderStatus,
      };
    });
    console.log(csvArray);
    setCsvData(csvArray);
  };

  const resetFilters = (e) => {
    e.preventDefault();
    setFilters({
      phoneNumber: '',
      customerName: '',
      orderStatus: '',
      orderDayFrom: '',
      orderMonthFrom: '',
      orderYearFrom: '',
      dateFrom: null,
      dateTo: null,
    });
    getOrdersFilteredApi({})
      .then((response) => {
        const sortedOrders = sortItemsByCategory(response);
        setOrders(sortedOrders);
        setReloadOrders(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyFilters = async (e) => {
    e.preventDefault();
    try {
      new Date(filters.dateFrom);
      new Date(filters.dateTo);
    } catch (e) {
      console.log(e);
      setFilters({ ...filters, dateTo: null, dateFrom: null });
    }

    if (filters.dateTo === null) {
      setFilters({ ...filters, dateTo: null, dateFrom: null });
    }

    await getOrdersFilteredApi(filters)
      .then((response) => {
        const sortedOrders = sortItemsByCategory(response);
        setOrders(sortedOrders);
        setReloadOrders(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <h1>Pedidos</h1>
      <Link to="/elpauporcjaris/order-stream">
        <Button>Pedidos en tiempo real (beta)</Button>
      </Link>
      <div className="filter-bar">
        <Form onSubmit={applyFilters}>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Fecha de entrega (Desde)</Form.Label>
                <Form.Control
                  name="dateFrom"
                  type="date"
                  onChange={handleDateChange}
                />
              </Col>
              <Col>
                <Form.Label>
                  Fecha de entrega (Hasta){' '}
                  <span style={{ fontSize: '7px', fontWeight: 'bold' }}>
                    NO INCLUÍDO
                  </span>
                </Form.Label>
                <Form.Control
                  name="dateTo"
                  type="date"
                  onChange={handleDateChange}
                />
              </Col>
            </Row>

            <Col>
              <Form.Label>Estado del pedido</Form.Label>
              <Form.Select
                name="orderStatus"
                aria-label="estado-pedido"
                value={filters.orderStatus}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    [e.target.name]: e.target.value,
                  })
                }
              >
                <option value="">CUALQUIERA</option>
                <option value="PENDIENTE">PENDIENTE</option>
                <option value="ENTREGADO">ENTREGADO</option>
                <option value="CANCELADO">CANCELADO</option>
                <option value="INCIDENCIA">INCIDENCIA</option>
                <option value="SOLUCIONADO">SOLUCIONADO</option>
              </Form.Select>
            </Col>

            <Col>
              <Col>
                <Form.Label>Teléfono</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  maxLength={9}
                  value={filters.phoneNumber}
                  onChange={(e) => {
                    if (e.target.value.length <= 9) {
                      setFilters({
                        ...filters,
                        [e.target.name]: e.target.value,
                      });
                    }
                  }}
                />
              </Col>
              <Col>
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  autoComplete="false"
                  name="customerName"
                  value={filters.customerName}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Col>
            </Col>

            <br />
            <Button type="submit" variant="success">
              Aplicar filtros
            </Button>
            <Button variant="dark" onClick={resetFilters}>
              Borrar filtros
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                downloadCSV();
              }}
            >
              <CSVLink
                data={csvData}
                filename={`pedidos_desde_${new Date(
                  filters.dateFrom
                ).toLocaleDateString()}_hasta_${new Date(
                  filters.dateTo
                ).toLocaleDateString()}.csv`}
                className="btn btn-primary"
                target="_blank"
              >
                Descargar CSV
              </CSVLink>
            </Button>

            <br />
            <br />
          </Form.Group>
        </Form>
      </div>
      <Table striped bordered hover size="md" responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Productos</th>
            <th>Info. cliente</th>
            <th>Estado</th>
            <th>Creado el</th>
            <th>Fecha de entrega</th>
            <th>Comentarios</th>
            <th>Total</th>
            <th>Entregar</th>
          </tr>
        </thead>
        <tbody>
          {orders.map &&
            orders.map((order, index) => (
              <tr key={order.id}>
                <td>{index + 1}</td>
                <td>
                  <div>
                    {order.items &&
                      Object.keys(order.items).map((category) => {
                        return (
                          <div>
                            <h6>{category}</h6>
                            {order.items[`${category}`].length > 0 &&
                              order.items[`${category}`].map((item) => {
                                return (
                                  <div key={`${order._id}${item._id}`}>
                                    {item.name} x {item.quantity}
                                  </div>
                                );
                              })}
                            <br />
                          </div>
                        );
                      })}
                  </div>
                </td>
                <td>
                  {order.customerName}
                  <br />
                  {order.phoneNumber}
                </td>
                <td>{order.orderStatus}</td>
                <td>
                  {new Date(order.createdAt).toLocaleDateString()}{' '}
                  {new Date(order.createdAt).toLocaleTimeString()}{' '}
                </td>
                <td>
                  {new Date(order.orderDate).toLocaleDateString()}{' '}
                  {new Date(order.orderDate).toLocaleTimeString()}{' '}
                </td>
                <td>{order.indications}</td>
                <td>{order.total}€</td>

                <td>
                  <BootstrapSwitchButton
                    data-onlabel="SÍ"
                    data-offlabel="NO"
                    checked={order.orderStatus === 'ENTREGADO' ? true : false}
                    disabled={order.orderStatus === 'ENTREGADO' ? true : false}
                    onChange={(checked) => {
                      order.orderStatus = checked ? 'ENTREGADO' : 'PENDIENTE';
                      updateOrderStatusApi(order._id, order.orderStatus).then(
                        (response) => {
                          setReloadOrders(true);
                        }
                      );
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}
