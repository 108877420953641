import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAdminAccessTokenApi } from '../api/auth';

import MenuTop from '../components/Admin/MenuTop';

import './LayoutAdmin.scss';
import { Container } from 'react-bootstrap';

export default function LayoutAdmin(props) {
  const { children } = props;

  const navigate = useNavigate();
  const accessToken = localStorage.getItem('ADMIN_ACCESS_TOKEN');

  useEffect(() => {
    if (!accessToken || accessToken === undefined || accessToken === null) {
      navigate('/elpauporcjaris');
    }
  }, [accessToken, navigate]);

  if (accessToken && (accessToken !== undefined || accessToken !== null)) {
    return (
      <>
        <MenuTop />
        <Container> {children}</Container>
      </>
    );
  }
  return null;
}
