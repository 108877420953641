import { getAdminAccessTokenApi } from './auth';
import { BASE_PATH, API_VERSION } from './config';

export async function getCategoriesApi() {
  const url = `${BASE_PATH}/${API_VERSION}/get-categories`;
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function updateCategoryApi(category, categoryId) {
  const token = await getAdminAccessTokenApi();

  const url = `${BASE_PATH}/${API_VERSION}/update-category/${categoryId}`;
  console.log(url);
  const params = {
    method: 'PUT',
    body: JSON.stringify(category),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function addCategoryApi(category) {
  const token = await getAdminAccessTokenApi();

  const url = `${BASE_PATH}/${API_VERSION}/add-category`;

  const params = {
    method: 'POST',
    body: JSON.stringify(category),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function deleteCategoryApi(categoryId) {
  const token = await getAdminAccessTokenApi();

  const url = `${BASE_PATH}/${API_VERSION}/drop-category/${categoryId}`;

  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
