import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setOrderToSolved } from '../../../../api/order';
import {
  getClaimsFilteredApi,
  updateClaimSolutionApi,
} from '../../../../api/claim';
import Modal from '../../../Modal';

import './ListClaims.scss';

const CoolChicken = require('../../../../assets/img/png/coolchicken.png');

export default function ListClaims() {
  const [claims, setOrders] = useState([]);
  const [reloadClaims, setReloadClaims] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: 'Default',
    body: 'Hello',
  });

  const [filters, setFilters] = useState({
    claimType: '',
    claimStatus: 'UNSOLVED',
    claimDay: '',
    claimMonth: '',
    claimYear: '',
  });

  useEffect(() => {
    getClaimsFilteredApi(filters)
      .then((response) => {
        setOrders(response);
        setReloadClaims(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reloadClaims]);

  const handleDateChange = (e) => {
    e.preventDefault();
    var filterDate = new Date(e.target.value);
    setFilters({
      ...filters,
      claimDay: filterDate.getUTCDate(),
      claimMonth: filterDate.getUTCMonth(),
      claimYear: filterDate.getUTCFullYear(),
    });
  };

  const resetFilters = async () => {
    setFilters({
      phoneNumber: '',
      customerName: '',
      orderStatus: '',
      claimDay: '',
      claimMonth: '',
      claimYear: '',
    });
    await getClaimsFilteredApi(filters)
      .then((response) => {
        setOrders(response);
        setReloadClaims(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const applyFilters = async (e) => {
    e.preventDefault();
    await getClaimsFilteredApi(filters)
      .then((response) => {
        setOrders(response);
        setReloadClaims(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setModalInfo({ show: false, title: 'Default', body: 'Hello' });
  };

  return (
    <div>
      <Modal
        show={modalInfo.show}
        title={modalInfo.title}
        body={modalInfo.body}
      />
      <h1>Reclamaciones</h1>
      <div className="filter-bar">
        <Form onSubmit={applyFilters}>
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Fecha</Form.Label>
                <Form.Control type="date" onChange={handleDateChange} />
                <Form.Label>Estado de la reclamación</Form.Label>
                <Form.Select
                  name="claimStatus"
                  aria-label="estado-pedido"
                  value={filters.claimStatus}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  <option value="">CUALQUIERA</option>
                  <option value="UNSOLVED">SIN SOLUCIONAR</option>
                  <option value="SOLVED">SOLUCIONADO</option>
                </Form.Select>
              </Col>
            </Row>
            <br />
            <Button type="submit" variant="success">
              Aplicar filtros
            </Button>
            <Button variant="dark" onClick={resetFilters}>
              Borrar filtros
            </Button>
            <br />
            <br />
          </Form.Group>
        </Form>
      </div>
      {claims.length > 0 && (
        <Table striped bordered hover size="md" responsive="sm">
          <thead>
            <tr key="header">
              <th>#</th>
              <th>Estado</th>
              <th>Razón</th>
              <th>Descripción</th>
              <th>Fecha de reclamación</th>
              <th>Fecha de solución</th>
              <th>Solución</th>
              <th>Pedido</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {claims.map &&
              claims.map((claim, index) => (
                <tr key={claim.id}>
                  <td>{index + 1}</td>
                  <td>{claim.claimStatus}</td>
                  <td>{claim.claimType}</td>
                  <td>{claim.description}</td>
                  <td>
                    {new Date(claim.createdAt).toLocaleDateString()}{' '}
                    {new Date(claim.createdAt).toLocaleTimeString()}{' '}
                  </td>
                  <td>
                    {claim.solvedAt
                      ? new Date(claim.solvedAt).toLocaleDateString()
                      : ''}{' '}
                    {claim.solvedAt
                      ? new Date(claim.solvedAt).toLocaleTimeString()
                      : ''}{' '}
                  </td>
                  <td>{claim.solution}</td>
                  <td>
                    <Link to={`/elpauporcjaris/pedidos/${claim.orderId}`}>
                      {claim.orderId}
                    </Link>{' '}
                  </td>
                  <td>
                    <Button
                      variant="outline-success"
                      disabled={claim.claimStatus === 'SOLVED'}
                      onClick={() => {
                        setModalInfo({
                          show: true,
                          title: 'Resolución de incidencia',
                          body: (
                            <SolveClaimForm
                              claimId={claim._id}
                              orderId={claim.orderId}
                              handleClose={handleClose}
                              setReloadClaims={setReloadClaims}
                            />
                          ),
                        });
                      }}
                    >
                      Resolución
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      {claims.length === 0 && filters.claimStatus === 'UNSOLVED' && (
        <>
          <div className="cool-chicken">
            <h3>¡Bien hecho!</h3>
            <h5>Aquí no queda nada que hacer</h5>
            <br />
            <img
              className="cart-image"
              src={CoolChicken}
              alt="el pollo paulino encargo realizado"
            />
          </div>
        </>
      )}
    </div>
  );
}

function SolveClaimForm(props) {
  const { claimId, orderId, handleClose, setReloadClaims } = props;
  const [solutionData, setSolutionData] = useState({
    solution: '',
    claimId: claimId,
  });
  const [error, setError] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (solutionData.solution.length > 10) {
      setError('');
      updateClaimSolutionApi(solutionData)
        .then(() => {
          //actualizar estado del pedido
          setOrderToSolved(orderId)
            .then(() => {
              setReloadClaims(true);
              handleClose();
            })
            .catch((e) => {
              setError(
                'Error al actualizar el estado del pedido. ' + e.message
              );
            });
        })
        .catch((e) => {
          setError('Error al enviar la resolución. ' + e.message);
        });
    } else {
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>Escribe la solución a la incidencia</Form.Label>
        <Form.Control
          as="textArea"
          name="solution"
          onChange={(e) =>
            setSolutionData({
              ...solutionData,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Escribe una solución al problema"
        />
        <p style={{ color: 'red' }}>{error}</p>
        <Button type="submit" variant="primary">
          Enviar Resolución
        </Button>
      </Form.Group>
    </Form>
  );
}
