import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import './Nav.scss';

import Carta from '../../../../assets/img/svg/menu-carta.svg';
import Encargo from '../../../../assets/img/svg/menu-encargo.svg';
import Contacto from '../../../../assets/img/svg/menu-contacto.svg';

export default function Nav() {
  return (
    <nav className="landing-container__items__nav">
      <NavItem menuItem={Carta} alt="Carta" url="#carta" />
      <div className="landing-container__items__nav__item">
        <Link to="/carta">
          {/* <img
            className="landing-container__items__nav__item__img"
            src={Encargo}
            style={{ width: `80%` }}
            alt="Encargo"
          /> */}
          Encargo
        </Link>
      </div>
      <NavItem menuItem={Contacto} alt="Contacto" url="#contacto" />
    </nav>
  );
}

export function NavItem(props) {
  const { menuItem, wide, alt, url } = props;
  const initialWidth = wide ? 98 : 80;

  return (
    <div className="landing-container__items__nav__item">
      <a href={url}>
        {/* <img
          className="landing-container__items__nav__item__img"
          src={menuItem}
          style={{ width: `${initialWidth}%` }}
          alt={alt}
        /> */}
        {alt}
      </a>
    </div>
  );
}
