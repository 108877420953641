import React from 'react';

import Landing from '../components/Web/Home/Landing';
import MakeOrder from '../components/Web/Home/MakeOrder';
import MenuList from '../components/Web/Home/MenuList';
import Contact from '../components/Web/Home/Contact';
import Footer from '../components/Web/Home/Footer';

export default function Home() {
  return (
    <div>
      <Landing />
      <section>
        <MakeOrder />
        <MenuList />
      </section>
      <aside>
        <Contact />
      </aside>
      <Footer />
    </div>
  );
}
