import { BASE_PATH, API_VERSION } from './config';
import { getAccessTokenApi, getAdminAccessTokenApi } from './auth';
import jwtDecode from 'jwt-decode';

export async function addOrderApi(payload) {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/create-order/${decodedToken.id}`;
  payload.customerName = decodedToken.name;

  const params = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      //Remove cart
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      //Remove cart
      return response;
    });
}

export async function getPendingOrdersApi() {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/get-order-by-status/${decodedToken.id}/PENDIENTE`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      //Remove cart
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      //Remove cart
      return response;
    });
}

export async function getPastOrdersApi() {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/get-order-by-status/${decodedToken.id}/ENTREGADO`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      //Remove cart
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      //Remove cart
      return response;
    });
}

export async function getUserOrdersApi() {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/get-user-orders/${decodedToken.id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getPendingAdminOrdersApi() {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/get-pending-orders`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getPendingOrdersByDateApi(date) {
  const token = await getAdminAccessTokenApi();
  const queryDate = new Date(date);
  const url = `${BASE_PATH}/${API_VERSION}/get-pending-orders/${queryDate.getUTCDate()}/${queryDate.getUTCMonth()}/${queryDate.getUTCFullYear()}`;
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getOrdersFilteredApi(filters) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/get-orders-filtered/`;
  const params = {
    method: 'POST',
    body: JSON.stringify(filters),
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function updateOrderStatusApi(orderId, orderStatus) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/update-order-status/${orderId}/${orderStatus}`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function getOrderApi(orderId) {
  const token = await getAccessTokenApi();

  const url = `${BASE_PATH}/${API_VERSION}/get-order/${orderId}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function setOrderToClaim(orderId) {
  const token = await getAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/update-order-to-claim/${orderId}/`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function setOrderToSolved(orderId) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/update-order-to-solved/${orderId}/`;
  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
