import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import AcceptingOrders from './AcceptingOrders/AcceptingOrders';
import OpenTimes from './OpenTimes';

import './Settings';

export default function Settings() {
  return (
    <div>
      <h1>Configuración</h1>
      <AcceptingOrders />
      {/* <OpenTimes /> */}
    </div>
  );
}
