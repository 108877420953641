import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const checkoutCompleted = require('../../../../assets/img/png/pedidorealizado.png');

export default function CheckOutCompleted() {
  return (
    <>
      <div className="empty-cart">
        <h3>¡Encargo realizado con éxito!</h3>
        <h5>Esperamos verte pronto</h5>
        <br />
        <img
          className="cart-image"
          src={checkoutCompleted}
          alt="el pollo paulino encargo realizado"
        />
        <br />
        <div className="show-menu-button">
          <Link to="/perfil">
            <Button variant="warning" size="md">
              Ver mis pedidos
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
}
