import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAccessTokenApi } from '../../../../api/auth';
import { getUserInfo, updateUserApi } from '../../../../api/user';
import useAuth from '../../../../hooks/useAuth';

import './EditUser.scss';

export default function EditUser() {
  const [userData, setUserData] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    postalCode: '',
    createdAt: '',
  });
  const [error, setError] = useState('');
  const [hasBeenEditted, setHasBeenEditted] = useState(false);
  const [success, setSuccess] = useState('');
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && !isLoading) {
      navigate('/carta');
    }
  }, [isLoading, user, navigate]);

  useEffect(() => {
    if (!isLoading) {
      getUserInfo()
        .then((response) => {
          setIsFetchingData(false);
          setUserData(response);
        })
        .catch((e) => {
          setIsFetchingData(false);

          setError(
            'No se ha podido cargar los datos de usuario. Inténtelo de nuevo más tarde.'
          );
        });
    }
  }, [user, isLoading]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasBeenEditted(false);
    if (userData.name.length === 0 || userData.lastName.length === 0) {
      setError('Los campos marcados con * no pueden estar vacíos');
    } else {
      updateUserApi(userData)
        .then((response) => {
          setSuccess('¡Cambios guardados correctamente!');
          setHasBeenEditted(false);
          setTimeout(() => {
            setSuccess('');
          }, 2000);
        })
        .catch((e) => {
          setHasBeenEditted(true);
          setError(
            'Ha ocurrido un error al actualizar los datos de tu perfil. Inténtelo de nuevo más tarde.'
          );
        });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setHasBeenEditted(true);
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  return (
    <Form onChange={handleChange} className="edit-profile-form">
      <h2>Datos personales</h2>
      {(isLoading || isFetchingData) && (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
          <span>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />{' '}
            Cargando...
          </span>
        </div>
      )}
      {!isLoading && !isFetchingData && (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Nombre*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={userData.name || ''}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Apellido*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={userData.lastName}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Teléfono*</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  value={userData.phoneNumber || ''}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Código postal</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={userData.postalCode || ''}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              name="email"
              disabled
              value={userData.email || ''}
            />
          </Form.Group>

          {userData.createdAt && (
            <p style={{ marginTop: '20px' }}>
              Miembro desde:{' '}
              <span>{new Date(userData.createdAt).toLocaleDateString()}</span>
            </p>
          )}
          <p style={{ color: 'red', fontSize: '11px' }}>{error}</p>
          <Button onClick={handleSubmit} disabled={!hasBeenEditted}>
            Guardar cambios
          </Button>
          <p style={{ color: 'green' }}>{success}</p>
        </>
      )}
    </Form>
  );
}
