import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { default as GoogleOauth } from '../GoogleOauth';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { signInApi, signUpApi } from '../../../../api/user';

import './SignIn.scss';

export default function SignIn(props) {
  const { handleClose, setModalTitle } = props;
  const [privacy, setPrivacy] = useState(false);
  const [registerMode, setRegisterMode] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });

  const [formValid, setFormValid] = useState({
    email: false,
    password: false,
  });
  const handleChange = (e) => {
    if (e.target.type !== 'checkbox') {
      e.preventDefault();
      setInputs({
        ...inputs,
        [e.target.name]: e.target.value, //Con esto el resto de inputs
      });
    } else {
      setPrivacy(e.target.checked);
      console.log(e.target.checked);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!registerMode) {
      const signInData = { email: inputs.email, password: inputs.password };
      await signInApi(signInData)
        .then((res) => {
          if (res.signUp) {
            navigate(`/verificacion-sms/${res.userId}`);
            window.location.reload();
          } else {
            window.location.reload();
          }
        })
        .catch((err) => {
          setError(err.message);
        })
        .then((response) => {});
    } else {
      if (
        !inputs.password ||
        !inputs.name ||
        !inputs.lastName ||
        !inputs.email
      ) {
        setError('Los campos marcados con * son obligatorios.');
        return;
      }
      if (inputs.password !== inputs.repeatPassword) {
        setError('Las contaseñas deben coincidir.');
        return;
      }
      if (!privacy) {
        setError(
          'Lo sentimos pero para usar este servicio tienes que aceptar nuestras condiciones de uso y la política de privacidad'
        );
        return;
      }
      setError('');
      await signUpApi(inputs)
        .then((response) => {
          console.log(response);
          if (response.ok) {
            navigate(`/verificacion-sms/${response.userId}`);
            window.location.reload();
          } else {
            setError(response.message);
          }
        })
        .catch((e) => {});
    }
  };
  return (
    <>
      <Form onChange={handleChange} onSubmit={handleSubmit} action="/pedido">
        {registerMode && (
          <>
            <Row>
              <Col md={6}>
                <Form.Label>Nombre*</Form.Label>
                <Form.Control type="text" name="name" placeholder="Nombre" />
              </Col>
              <Col md={6}>
                <Form.Label>Apellido*</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Apellido"
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Label>Código postal</Form.Label>
                <Form.Control
                  type="number"
                  name="postalCode"
                  placeholder="Código postal"
                  maxLength={5}
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col md={12}>
            <Form.Label>Correo electrónico*</Form.Label>
            <Form.Control
              autoComplete="email"
              type="email"
              placeholder="Introduce tu email"
              name="email"
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Form.Label>Contraseña*</Form.Label>
            <Form.Control
              autoComplete="current-password"
              name="password"
              type="password"
              placeholder="Contraseña"
            />
          </Col>
        </Row>

        {registerMode && (
          <Row>
            <Col md={12}>
              <Form.Label>Repite la contraseña*</Form.Label>
              <Form.Control
                autoComplete="current-password"
                name="repeatPassword"
                type="password"
                placeholder="Repite la contraseña"
              />
              <Form.Text className="text-muted"></Form.Text>
            </Col>
          </Row>
        )}

        {!registerMode && (
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Link
              to="/reset-password"
              onClick={() => {
                handleClose();
              }}
            >
              He olvidado mi contraseña
            </Link>
          </Form.Group>
        )}
        {!registerMode && (
          <p>
            ¿Aún no tienes una cuenta?{' '}
            <span
              className="register-sign-in-button"
              onClick={() => {
                setRegisterMode(true);
                setModalTitle('Registro');
              }}
            >
              Regístrate
            </span>
          </p>
        )}
        <Form.Group>
          {registerMode && (
            <Row>
              <Col>
                <br />

                <Form.Check
                  label="He leído y acepto la política de privacidad y condiciones de uso"
                  name="privacy"
                  value={privacy}
                />
                <br />
                <p>
                  <a href="/privacidad-y-condiciones-de-uso">
                    Ver política de privacidad y condiciones de uso
                  </a>
                </p>
              </Col>
            </Row>
          )}
          <p style={{ color: 'red' }}>{error}</p>
          <Button variant="primary" type="submit" style={{ width: '100%' }}>
            {registerMode ? 'Registrarme' : 'Iniciar sesión'}
          </Button>
        </Form.Group>

        <p style={{ textAlign: 'center', marginTop: '10px' }}>O</p>

        {registerMode && (
          <p>
            ¿Ya tienes una cuenta?{' '}
            <span
              className="register-sign-in-button"
              onClick={() => {
                setRegisterMode(false);
                setModalTitle('Iniciar Sesión');
              }}
            >
              Iniciar sesión
            </span>
          </p>
        )}
        <Form.Group
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <GoogleOauth handleClose={handleClose} />
          <p
            style={{
              fontSize: '11px',
              textAlign: 'center',
              marginTop: '20px',
            }}
          >
            Al iniciar sesión con un servicio externo estás aceptando nuestra{' '}
            <a href="/privacidad-y-condiciones-de-uso">
              política de privacidad y condiciones de uso
            </a>
            .
          </p>
        </Form.Group>
      </Form>
    </>
  );
}
