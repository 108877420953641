import React, { useEffect, useState } from 'react';
import AddCategory from '../../../components/Admin/Products/AddCategory/AddCategory';
import { Button } from 'react-bootstrap';
import { getCategoriesApi, updateCategoryApi } from '../../../api/category';
import ListProducts from '../../../components/Admin/Products/ListProducts';
import Modal from '../../../components/Modal';
export default function Products() {
  const [categories, setCategories] = useState([]);
  const [refreshProducts, setRefreshProducts] = useState(false);
  const [updateActiveProducts, setUpdateActiveProducts] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    show: false,
    title: 'Default',
    body: 'Hello',
  });

  useEffect(() => {
    getCategoriesApi().then((response) => {
      setCategories(response.categories);
      setRefreshProducts(false);
    });
  }, [refreshProducts]);

  useEffect(() => {
    let catArr = categories;
    catArr.forEach((category) => {
      let hasActiveProducts = false;
      category.products.forEach((product) => {
        if (product.active) {
          hasActiveProducts = true;
        }
      });
      if (category.hasActiveProducts !== hasActiveProducts) {
        category.hasActiveProducts = hasActiveProducts;
        updateCategoryApi(category, category._id).then(() => {
          setUpdateActiveProducts(false);
        });
      } else {
        setUpdateActiveProducts(false);
      }
    });
  }, [updateActiveProducts]);

  const handleClose = () => {
    setModalInfo({ show: false, title: 'Default', body: 'Hello' });
  };

  return (
    <div>
      <Modal
        show={modalInfo.show}
        handleClose={handleClose}
        title={modalInfo.title}
        body={modalInfo.body}
      />
      <h1>Productos</h1>
      <Button
        variant="outline-dark"
        style={{ margin: '20px 0 20px 0' }}
        onClick={() => {
          setModalInfo({
            title: `Añadir categoría`,
            body: (
              <AddCategory
                closeModal={handleClose}
                setRefreshProducts={setRefreshProducts}
              />
            ),
            show: true,
            wide: true,
          });
        }}
      >
        Añadir categoría
      </Button>

      <ListProducts
        setCategories={setCategories}
        categories={categories}
        setUpdateActiveProducts={setUpdateActiveProducts}
        setRefreshProducts={setRefreshProducts}
        handleClose={handleClose}
        setModalInfo={setModalInfo}
      />
    </div>
  );
}
