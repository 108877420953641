import React from 'react';

import Menu from '../components/Web/Orders/Menu/Menu';

export default function Order() {
  return (
    <>
      <Menu />
    </>
  );
}
