import { API_VERSION, BASE_PATH } from './config';
import jwtDecode from 'jwt-decode';

export async function getAccessTokenApi() {
  const accessToken = localStorage.getItem('ACCESS_TOKEN');
  if (!accessToken || accessToken === 'null' || accessToken === undefined) {
    logout();
    return null;
  }

  if (isTokenExpired(accessToken)) {
    await refreshAccessToken();
    getAccessTokenApi();
  } else {
    return accessToken;
  }
}

export function getRefreshTokenApi() {
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');

  if (refreshToken === 'null' || !refreshToken) {
    logout();
    return null;
  }

  return isTokenExpired(refreshToken) ? null : refreshToken;
}

export async function refreshAccessToken() {
  const url = `${BASE_PATH}/${API_VERSION}/refresh-access-token`;
  const refreshToken = localStorage.getItem('REFRESH_TOKEN');
  const params = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ REFRESH_TOKEN: refreshToken }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await fetch(url, params)
    .then((res) => {
      if (res.status !== 200) {
        return null;
      }
      return res.json();
    })
    .then((response) => {
      if (!response) {
        logout();
      } else {
        localStorage.setItem('ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('REFRESH_TOKEN', response.refreshToken);
      }
    });
}
/**
 * Logout the user A.K.A. removing access tokens from local storage
 */
export function logout() {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
  localStorage.removeItem('ADMIN_ACCESS_TOKEN');
  localStorage.removeItem('ADMIN_REFRESH_TOKEN');
}

/**
 * Returns true if the token has expired and false if it's still valid
 * @param {*} token
 * @returns true if the token has expired and false if it's still valid
 */
export function isTokenExpired(token) {
  try {
    const seconds = 60;
    const metaToken = jwtDecode(token);
    const { expires } = metaToken;
    const now = (Date.now() + seconds) / 1000;
    return now > expires;
  } catch (e) {
    logout();
  }
}

export async function getUserFromAccessToken() {
  const accessToken = getAccessTokenApi();
  console.log('getUserFromAccessToken: ' + accessToken);
  if (!isTokenExpired(accessToken)) {
    console.log('Valid token..');
    return jwtDecode(accessToken);
  } else {
    try {
      await refreshAccessToken();
      getUserFromAccessToken();
    } catch (e) {
      logout();
      console.log(e);
      return null;
    }
  }
}

export async function refreshAdminAccessToken() {
  const url = `${BASE_PATH}/${API_VERSION}/refresh-admin-access-token`;
  const refreshToken = localStorage.getItem('ADMIN_REFRESH_TOKEN');

  const params = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ ADMIN_REFRESH_TOKEN: refreshToken }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  await fetch(url, params)
    .then((res) => {
      if (res.status !== 200) {
        return null;
      }
      return res.json();
    })
    .then((response) => {
      if (!response) {
        logout();
      } else {
        localStorage.setItem('ADMIN_ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('ADMIN_REFRESH_TOKEN', response.refreshToken);
      }
    });
}

export async function getAdminAccessTokenApi() {
  const accessToken = localStorage.getItem('ADMIN_ACCESS_TOKEN');
  if (!accessToken || accessToken === 'null' || accessToken === undefined) {
    logout();
    return null;
  }

  if (isTokenExpired(accessToken)) {
    refreshAdminAccessToken();
  } else {
    return accessToken;
  }
}
