import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInGoogleOauthApi } from '../../../../api/user';

export default function GoogleOauth(props) {
  const navigate = useNavigate();
  const handleCallbackResponse = async (response) => {
    await signInGoogleOauthApi({ jwt: response.credential })
      .then((res) => {
        if (res.signUp) {
          navigate(`/verificacion-sms/${res.userId}`);
          window.location.reload();
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        '324943848771-7i43suhk79n00hk75gjvi9j7g4s0v6n8.apps.googleusercontent.com',
      callback: handleCallbackResponse,
      response_type: 'token',
    });

    google.accounts.id.renderButton(
      document.getElementById('google-oauth-button'),
      {
        theme: 'outline',
        size: 'large',
      }
    );
  }, []);

  return <div id="google-oauth-button"></div>;
}
