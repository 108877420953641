import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faListUl,
  faCartShopping,
  faUser,
  faSignOut,
} from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from '../../../Modal/Modal';
import SignIn from '../SignIn/SignIn';
import useAuth from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';

import logo from '../../../../assets/img/logo/logo-no-rotulo.png';
import { logout } from '../../../../api/auth';

export default function NavbarOrder() {
  const { user, isLoading } = useAuth();
  const [modalShow, setModalShow] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleShow = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="light"
      variant="light"
      expanded={expanded}
    >
      <Modal
        show={modalShow}
        handleClose={handleClose}
        title="Iniciar Sesión"
        body={<SignIn handleClose={handleClose} />}
      />
      <Container>
        <Navbar.Brand href="/carta" style={{ marginLeft: '15px' }}>
          <img
            src={logo}
            width="50"
            height="50"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <div className="navbar-icons">
          {window.innerWidth <= 1023 && (
            <Navbar.Text>
              <Link
                onClick={() => setExpanded(false)}
                to="/carrito"
                style={{
                  color: 'rgba(0, 0, 0, 0.55)',
                  textDecoration: 'none',
                }}
              >
                <FontAwesomeIcon
                  size="lg"
                  icon={faCartShopping}
                  style={{ marginRight: '25px' }}
                />
                {/* <span style={{ marginRight: '25px' }}>(7)</span> */}
              </Link>
            </Navbar.Text>
          )}

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={() => setExpanded(expanded ? false : 'expanded')}
          />
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link>
              <Link
                onClick={() => setExpanded(false)}
                to="/"
                style={{
                  color: 'rgba(0, 0, 0, 0.55)',
                  textDecoration: 'none',
                }}
              >
                Inicio
              </Link>
            </Nav.Link>

            <Nav.Link>
              <Link
                to="/carta"
                style={{
                  color: 'rgba(0, 0, 0, 0.55)',
                  textDecoration: 'none',
                }}
                onClick={() => setExpanded(false)}
              >
                Carta
              </Link>
            </Nav.Link>
            {/* <NavDropdown title="Pedido" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#pedido/recoger">
                Para recoger
              </NavDropdown.Item>
              <NavDropdown.Item href="#pedido/glovo">
                A domicilio
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
          <Nav>
            {user && !isLoading && window.innerWidth > 1023 && (
              <Nav.Link>
                <Link
                  onClick={() => setExpanded(false)}
                  to="/carrito"
                  style={{
                    color: 'rgba(0, 0, 0, 0.55)',
                    textDecoration: 'none',
                  }}
                >
                  <FontAwesomeIcon icon={faCartShopping} /> Carrito
                </Link>
              </Nav.Link>
            )}

            {!user && !isLoading && (
              <Nav.Link eventKey={2} onClick={handleShow}>
                <FontAwesomeIcon icon={faUser} /> Iniciar Sesión
              </Nav.Link>
            )}
            {user && !isLoading && (
              <>
                <Nav.Link>
                  <Link
                    onClick={() => setExpanded(false)}
                    to="/perfil"
                    style={{
                      color: 'rgba(0, 0, 0, 0.55)',
                      textDecoration: 'none',
                    }}
                  >
                    <FontAwesomeIcon icon={faListUl} /> Mis pedidos
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    onClick={() => setExpanded(false)}
                    to="/ajustes"
                    style={{
                      color: 'rgba(0, 0, 0, 0.55)',
                      textDecoration: 'none',
                    }}
                  >
                    <FontAwesomeIcon icon={faUser} /> Mi cuenta
                  </Link>
                </Nav.Link>
                <Nav.Link>
                  <Link
                    onClick={() => {
                      logout();
                      window.location.reload();
                    }}
                    style={{
                      color: 'rgba(0, 0, 0, 0.55)',
                      textDecoration: 'none',
                    }}
                  >
                    <FontAwesomeIcon icon={faSignOut} /> Cerrar sesión
                  </Link>
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
