//Conexion endpoints usuario
import { BASE_PATH, API_VERSION } from './config';
import { getAccessTokenApi, getAdminAccessTokenApi } from './auth';
import jwtDecode from 'jwt-decode';

export async function signUpApi(data) {
  const url = `${BASE_PATH}/${API_VERSION}/sign-up`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://elpollopaulino.netlify.app',
      Origin: 'https://elpollopaulino.netlify.app', // or the URL of your app
    },
  };
  return fetch(url, params)
    .then((res) => res.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((response) => {
      if (response.user) {
        return {
          userId: response.user._id,
          ok: true,
          message: 'Usuario creado correctamente',
        };
      }
      return { ok: false, message: response.message };
    });
}

export async function signInApi(data) {
  const url = `${BASE_PATH}/${API_VERSION}/sign-in`;
  const params = {
    method: 'POST',
    body: JSON.stringify(data),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://elpollopaulino.netlify.app',
      Origin: 'https://elpollopaulino.netlify.app', // or the URL of your app
    },
  };

  return fetch(url, params)
    .then((res) => res.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((response) => {
      if (response.accessToken && response.refreshToken) {
        localStorage.setItem('ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('REFRESH_TOKEN', response.refreshToken);
      } else {
        response.error =
          'No se ha podido realizar el login correctamente. Por favor, intentalo de nuevo';
      }

      return response;
    });
}

export async function getUsersActiveApi(status) {
  const token = await getAdminAccessTokenApi();
  const url = `${BASE_PATH}/${API_VERSION}/users-active?active=${status}`;
  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => response.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((result) => {
      return result;
    });
}

export async function uploadAvatarApi(token, avatar, userId) {
  const url = `${BASE_PATH}/${API_VERSION}/upload-avatar/${userId}`;
  const formData = new FormData();
  formData.append('avatarName', avatar, avatar.name);

  const params = {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: token,
    },
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function getAvatarApi(avatarName) {
  const url = `${BASE_PATH}/${API_VERSION}/get-avatar/${avatarName}`;

  return fetch(url)
    .then((response) => {
      return response.url;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function updateUserApi(user) {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/update-user/${decodedToken.id}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(user),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function activateUserApi(token, status, userId) {
  const url = `${BASE_PATH}/${API_VERSION}/activate-user/${userId}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ active: status }),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function deleteUserApi(token, userId) {
  const url = `${BASE_PATH}/${API_VERSION}/delete-user/${userId}`;

  const params = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify({ id: userId }),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function signUpAdminApi(token, data) {
  const url = `${BASE_PATH}/${API_VERSION}/sign-up-admin`;
  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(data),
  };
  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export async function signInGoogleOauthApi(jwt) {
  const url = `${BASE_PATH}/${API_VERSION}/sign-up-google-oauth2`;
  const params = {
    method: 'POST',
    body: JSON.stringify(jwt),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://elpollopaulino.netlify.app',
      Origin: 'https://elpollopaulino.netlify.app', // or the URL of your app
    },
  };

  return fetch(url, params)
    .then((res) => res.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((response) => {
      if (response.accessToken && response.refreshToken) {
        localStorage.setItem('ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('REFRESH_TOKEN', response.refreshToken);
      } else {
        response.error =
          'No se ha podido realizar el login correctamente. Por favor, intentalo de nuevo';
        response.ok = false;
      }
      return response;
    });
}

export async function activateUserWithTokenApi(activationId, userId, jwt) {
  const url = `${BASE_PATH}/${API_VERSION}/activate-account`;
  const params = {
    method: 'POST',
    body: JSON.stringify({
      token: jwt,
      userId: userId,
      activationId: activationId,
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'https://elpollopaulino.netlify.app',
      Origin: 'https://elpollopaulino.netlify.app', // or the URL of your app
    },
  };

  return fetch(url, params)
    .then((res) => res.json())
    .catch((err) => {
      return { ok: false, message: err.message };
    })
    .then((response) => {
      if (response.accessToken && response.refreshToken) {
        localStorage.setItem('ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('REFRESH_TOKEN', response.refreshToken);
      } else {
        response.error =
          'No se ha podido realizar el login correctamente. Por favor, intentalo de nuevo';
      }

      return response;
    });
}

export async function getUserInfo() {
  const token = await getAccessTokenApi();
  const decodedToken = jwtDecode(token);
  const url = `${BASE_PATH}/${API_VERSION}/get-user-info/${decodedToken.id}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}

export async function askForPasswordChange(email) {
  const url = `${BASE_PATH}/${API_VERSION}/ask-password-change/${email}`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
export async function sendPhoneVerification(phoneNumber, userId) {
  const url = `${BASE_PATH}/${API_VERSION}/send-phone-verification/`;

  const params = {
    method: 'POST',
    body: JSON.stringify({ phoneNumber: phoneNumber, userId: userId }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      return response;
    });
}
export async function sendOTPVerification(
  phoneNumber,
  phoneNumberLocal,
  otpcode,
  userId
) {
  const url = `${BASE_PATH}/${API_VERSION}/verify-otp/`;

  const params = {
    method: 'POST',
    body: JSON.stringify({
      phoneNumberInt: phoneNumber,
      phoneNumber: phoneNumberLocal,
      otpcode: otpcode,
      userId: userId,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(url, params)
    .then((res) => {
      return res.json();
    })
    .catch((e) => {
      return e.message;
    })
    .then((response) => {
      if (response.accessToken && response.refreshToken) {
        localStorage.setItem('ACCESS_TOKEN', response.accessToken);
        localStorage.setItem('REFRESH_TOKEN', response.refreshToken);
      }

      return response;
    });
}
